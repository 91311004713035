import React from 'react';
import { Button } from 'react-bootstrap';
import cn from 'classnames';
import { UserPoolSetting } from '../../RealtimeMap';

type Props = {
  classNames?: string;
  caption: string;
  isSelected?: boolean;
  users?: {
    clientId: string;
    name?: string;
    setting: UserPoolSetting;
  }[];
  onClick?: () => void;
};

const MapTypeButton = ({
  classNames,
  caption,
  isSelected,
  users = [],
  onClick,
}: Props) => {
  return (
    <div className='position-relative MapTypeButtonWrapper'>
      {users.length > 0 ? (
        <div className='UserAvatarContainer'>
          {users.map((user) => (
            <div
              className='rounded-circle avatar avatar--mini'
              key={user.clientId}
              style={{
                background: user.setting.color.hex,
                color: `rgb(${user.setting.color.textColor.join(',')})`,
              }}
            >
              {user.clientId?.charAt(0).toUpperCase()}
            </div>
          ))}
        </div>
      ) : null}
      <Button
        className={cn('MapTypeButton', {
          ...(classNames && {
            [classNames]: classNames,
          }),
          selected: isSelected,
        })}
        data-label={caption}
        onClick={onClick}
      >
        {caption}
      </Button>
    </div>
  );
};

export default MapTypeButton;
