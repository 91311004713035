import React from 'react';
import DeckGL from '@deck.gl/react';
import * as visGm from '@vis.gl/react-google-maps';
import { BSON } from 'bson';
import { Position, Feature, Point } from 'geojson';
import invariant from 'tiny-invariant';
import {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import proj4 from 'proj4';
import * as turf from '@turf/turf';
import * as dateFns from 'date-fns';
import useWebSocket from 'react-use-websocket';
import { GeoJsonLayer, GeoJsonLayerProps, TextLayer } from '@deck.gl/layers';
import { MaskExtension, CollisionFilterExtension } from '@deck.gl/extensions';
import {
  Change,
  changeOps,
  Client,
  FrequentChange,
  FrequentUpdateRequest,
  idLib,
  JoinRequest,
  RESPONSE_SCHEMA,
  SyncRequest,
  UpdateRequest,
} from '@larki/mp';

import { positionLib } from './lib/position';

import { useThrottle } from './hooks/useThrottle';
import { vertexLib } from './lib/vertex';
import ShapeLayer, {
  ShapeHandleLayer,
  ShapeLayerPickingInfoT,
  toShapeFeatures,
} from './shapeLayer';
import { WorldState, WorldStateT } from './worldState';
import DeleteVertexLayer, {
  DeleteVertexLayerPickingInfoT,
} from './deleteVertexLayer';
import useIsTabActive from './hooks/useIsTabActive';
import { useKeyPress } from './hooks/useKeyPress';
import { usePrevious } from './hooks/usePrevious';
import { WorldStateInterpolator } from './interpolator';
import { rectangleLib } from './lib/rectangle';
import {
  BooleanVertexFeature,
  MapPath,
  MapPolygon,
  MapRectangle,
  PathVertexFeature,
  PolygonVertexFeature,
  RectangleVertexFeature,
  SHAPE_TYPE,
  TentativeShapeType,
} from './lib/shape';

import CursorLayer from './cursorLayer';
import MapTypeButton from './components/map-type-button';
import AddVertexLayer, { AddVertexLayerPickingInfoT } from './addVertexLayer';
import useMovingAverage from './hooks/useMovingAverage';
import DebugMetrics, { Metrics } from './DebugMetrics';
import { useCoverage } from '../coverage2/coverage2';
import useThrottledValue from './hooks/useThrottledValue';

import { arrayLib } from './lib/array';
import ProductControls from './ProductControls';
import DrawingControls from './DrawingControls';

import {
  DATA_TYPES,
  DERIVED_DATA_TYPES,
  getBaseDataType,
  isDerivedDataType,
} from './lib/dataType';

import './RealtimeMap.scss';
import './styles/main.scss';
import {
  copyPath,
  copyPolygon,
  copyRectangle,
  mapChangeOps,
} from './lib/mapChangeOps';
import { geometryLib } from './lib/geometry';

const MAP_TYPES = ['roadmap', 'satellite'];
const PROJECTION = proj4('WGS84', 'EPSG:3857');

const INITIAL_MAP_OPTIONS = {
  CENTER: [144.9623, -37.8124] satisfies [number, number], // https://geojson.io/#map=18/-37.8124/144.9623
  ZOOM: 18,
};
const GM_MAP_OPTIONS: visGm.MapProps = {
  style: { width: '100vw', height: '100vh' },
  defaultCenter: {
    lng: INITIAL_MAP_OPTIONS.CENTER[0],
    lat: INITIAL_MAP_OPTIONS.CENTER[1],
  },
  defaultZoom: INITIAL_MAP_OPTIONS.ZOOM,
  disableDefaultUI: true,
  isFractionalZoomEnabled: true,
  styles: [
    {
      featureType: 'poi',
      elementType: 'labels',
      stylers: [{ visibility: 'off' }],
    },
  ],
  tilt: 0,
};
const MAP_ID = 'map';
const MIN_DISTANCE_TO_DELETE_VERTEX_PIXELS = 500;
const MIN_DISTANCE_TO_CREATE_VERTEX_PIXELS = 500;

export type Mode =
  | 'idle'
  | 'creating_polygon'
  | 'creating_rectangle'
  | 'creating_path'
  | 'creating_boolean';
type CreatingMode = Exclude<Mode, 'idle'>;
export type ModeAction =
  | 'start_creating_polygon'
  | 'start_creating_rectangle'
  | 'start_creating_path'
  | 'start_creating_boolean'
  | 'stop_creating';
const modeReducer = (mode: Mode, action: ModeAction): Mode => {
  if (action === 'start_creating_polygon') {
    return 'creating_polygon';
  } else if (action === 'start_creating_rectangle') {
    return 'creating_rectangle';
  } else if (action === 'start_creating_path') {
    return 'creating_path';
  } else if (action === 'start_creating_boolean') {
    return 'creating_boolean';
  } else if (action === 'stop_creating') {
    return 'idle';
  }
  return mode;
};
const isModeCreating = (mode: Mode): mode is CreatingMode => mode !== 'idle';

class ModeNotCreatingError extends Error {}
const creatingModeToTentativeShapeType = (
  mode: CreatingMode
): TentativeShapeType => {
  switch (mode) {
    case 'creating_polygon':
      return 'polygon';
    case 'creating_rectangle':
      return 'rectangle';
    case 'creating_path':
      return 'path';
    case 'creating_boolean':
      return 'boolean';
    default:
      throw new ModeNotCreatingError();
  }
};

type WebsocketStatus =
  | 'idle'
  | 'initializing'
  | 'joining'
  | 'syncing'
  | 'synced';
const websocketStatusReducer = (
  state: WebsocketStatus,
  action: 'connect' | 'initialize' | 'join' | 'sync' | 'disconnect' | 'resync'
): WebsocketStatus => {
  if (state === 'idle' && action === 'connect') {
    return 'initializing';
  } else if (state === 'initializing' && action === 'initialize') {
    return 'joining';
  } else if (state === 'joining' && action === 'join') {
    return 'syncing';
  } else if (state === 'syncing' && action === 'sync') {
    return 'synced';
  } else if (state === 'synced' && action == 'resync') {
    return 'syncing';
  } else if (action === 'disconnect') {
    return 'idle';
  }
  return state;
};

type PickingInfoT =
  | ShapeLayerPickingInfoT
  | DeleteVertexLayerPickingInfoT
  | AddVertexLayerPickingInfoT;

type MidpointVertex = PolygonVertexFeature | PathVertexFeature;
type BaseMidpointToCreateVertex<V = MidpointVertex> = {
  before: V;
  after: V;
  position: Position;
  xyDistance: number;
};

export type PolygonMidpointToCreateVertex =
  BaseMidpointToCreateVertex<PolygonVertexFeature> & {
    shapeType: 'polygon';
  };
export type PathMidpointToCreateVertex =
  BaseMidpointToCreateVertex<PathVertexFeature> & {
    shapeType: 'path';
  };
export type PolygonBooleanMidpointToCreateVertex = BaseMidpointToCreateVertex<
  BooleanVertexFeature<'polygon'>
> & {
  parentShapeType: 'polygon';
  shapeType: 'boolean';
};
export type RectangleBooleanMidpointToCreateVertex = BaseMidpointToCreateVertex<
  BooleanVertexFeature<'rectangle'>
> & {
  parentShapeType: 'rectangle';
  shapeType: 'boolean';
};
export type MidpointToCreateVertex =
  | PolygonMidpointToCreateVertex
  | PathMidpointToCreateVertex
  | PolygonBooleanMidpointToCreateVertex
  | RectangleBooleanMidpointToCreateVertex;

export type ClientColor = {
  rgb: number[];
  textColor: number[];
  hex: string;
};

export interface UserPoolSetting {
  color: ClientColor;
  mapType: string;
}

export type UserPool = {
  [key: string]: {
    setting: UserPoolSetting;
  };
};

type MapType = 'roadmap' | 'satellite';

type Rgb = [number, number, number];
type Rgba = [number, number, number, number];

const DATA_TYPE_TO_COLOR: Record<string, Rgb> = {
  streetscape: [48, 72, 201],
  aerial: [21, 125, 170],
  drone: [176, 135, 243],
  exterior: [229, 83, 132],
  interior: [244, 118, 1],
};

const Consumer = () => {
  const [wsStatus, dispatchWsStatus] = useReducer(
    websocketStatusReducer,
    'idle'
  );

  const didUnmount = useRef(false);
  useEffect(() => {
    return () => {
      didUnmount.current = true;
    };
  }, []);
  const { getWebSocket, lastMessage, sendMessage } = useWebSocket(
    process.env.MP_SERVER_URL!,
    {
      onOpen: () => {
        console.log('CONNECTING');
        dispatchWsStatus('connect');
      },
      onClose: () => {
        console.log('DISCONNECTING');
        dispatchWsStatus('disconnect');
      },
      shouldReconnect: () => {
        return !didUnmount.current;
      },
      reconnectAttempts: 10,
      reconnectInterval: 3000,
    }
  );

  // todo: can these be merged, client ID in the constructor?
  const client = useRef(new Client());
  const clientId = useRef<string | null>(null);
  // const userPool = useRef<UserPool>({});

  const [mode, dispatchMode] = useReducer(modeReducer, 'idle');

  // START useStates
  const [userPool, setUserPool] = useState<UserPool>({});
  // TODO: use constants MAP_TYPE from frontend\src\components\mapView\constants.js
  const [mapType, setMapType] = useState('roadmap');
  const userPoolArray = useMemo(
    () =>
      Object.entries(userPool)
        .filter(([cId]) => cId !== clientId.current)
        .map(([client, value]) => ({
          clientId: client,
          setting: value.setting,
        })),
    [userPool]
  );
  const groupedMapType = useMemo(
    () =>
      MAP_TYPES.reduce(
        (acc, type) => {
          acc[type as MapType] = userPoolArray.filter(
            (item) => item.setting.mapType === type
          );
          return acc;
        },
        {} as Record<MapType, typeof userPoolArray>
      ),
    [userPoolArray]
  );

  const [tentativeSequencesData, setTentativeSequencesData] = useState<
    WorldStateT['tentativeShapes']
  >(new Map());
  const [shapesData, setShapesData] = useState<WorldStateT['shapes']>(
    new Map()
  );
  const [interpolatedPositions, setInterpolatedPositions] = useState<
    Record<string, Position>
  >({});
  const [clientIdToDraggingVertexIds, setClientIdToDraggingVertexId] = useState<
    Map<string, string>
  >(new Map());

  // polygon-related state, should always be null if in any 'creating' mode
  const [vertexToDelete, setVertexToDelete] = useState<
    | PolygonVertexFeature
    | PathVertexFeature
    | BooleanVertexFeature<'polygon'>
    | BooleanVertexFeature<'rectangle'>
    | null
  >(null);

  const [midpointToCreateVertex, setMidpointToCreateVertex] =
    useState<MidpointToCreateVertex | null>(null);

  // next vertex ID, should be null if not in a 'creating' mode
  const [nextVertexId, setNextVertexId] = useState<string | null>(null);

  // currently "active" shape IDs - Map->Set to be forwards compatible with multiple active shapes
  const [activeShapeIds, setActiveShapeIds] = useState<Set<string>>(new Set());

  const [hoveredDataType, setHoveredDataType] = useState<string | null>(null);

  const [hoverState, setHoverState] = useState<
    'map' | 'inactive' | 'active' | 'handle'
  >('map');

  // END useStates

  const { movingAverages: metrics, updateMovingAverages: updateMetrics } =
    useMovingAverage<Metrics>(3);

  const isRedundantModeAction = useCallback(
    (action: ModeAction) => modeReducer(mode, action) === mode,
    [mode]
  );

  const tick = useCallback(
    (worldState: WorldState | null, prevWorldState: WorldState | null) => {
      if (!clientId.current) return;
      if (!worldState) return;
      const predicted = client.current.applyPredictions(
        worldState.getState(),
        new Set(worldState.getChanges().map((c) => c.id))
      );
      const newWorldState = new WorldState(predicted, worldState.getChanges());
      let skipShapes = false;
      let skipTentativeSequences = false;
      if (prevWorldState) {
        const diff = newWorldState.diff(prevWorldState);
        if (diff.shapesEqual) {
          skipShapes = true;
        }
        if (diff.tentativeShapesEqual) {
          skipTentativeSequences = true;
        }
      }
      if (!skipShapes) {
        setShapesData(newWorldState.getShapes());
      }
      if (!skipTentativeSequences) {
        setTentativeSequencesData(newWorldState.getTentativeSequences());
      }
      setInterpolatedPositions(Object.fromEntries(newWorldState.getCursors()));
      setClientIdToDraggingVertexId(
        newWorldState.getClientInfo().clientIdToDraggingVertexId
      );
    },
    []
  );

  const interpolator = useMemo(() => new WorldStateInterpolator(), []);

  const {
    updateShapeExtents,
    coverageLayerGeometries,
    coverageLayerLabels,
    updateLabels,
  } = useCoverage();

  const shapeBounds = useMemo(
    () => toShapeFeatures(shapesData, new Map()),
    [shapesData]
  );
  const shapeBoundsThrottled = useThrottledValue({
    value: shapeBounds,
    throttleMs: 100,
  });

  const activateShape = useCallback((shapeId: string) => {
    setActiveShapeIds(new Set([shapeId]));
  }, []);
  const deactivateAllShapes = useCallback(() => {
    setActiveShapeIds(new Set());
  }, []);

  useEffect(() => {
    // main loop
    const loop = () => {
      if (interpolator.hasInterpolatedWorldStateChanged()) {
        const prevWorldState = interpolator.getPrevInterpolatedWorldState();
        const worldState = interpolator.getInterpolatedWorldState();
        tick(worldState, prevWorldState);
      }
      requestAnimationFrame(loop);
    };
    const frame = requestAnimationFrame(loop);
    return () => cancelAnimationFrame(frame);
  }, [interpolator, tick]);

  useEffect(() => {
    updateShapeExtents(
      shapeBoundsThrottled.reduce<(typeof shapeBoundsThrottled)[number][]>(
        (acc, shape) =>
          acc.concat(
            DATA_TYPES.map((dataType) => ({
              ...shape,
              properties: { ...shape.properties, dataType },
            }))
          ),
        []
      )
    );
    // TODO: use a heavily simplified version of the path features to update the labels for more efficient intersection computationQ

    updateLabels(shapeBoundsThrottled);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shapeBoundsThrottled]);

  const sendUpdate = useCallback(
    (changes: Change[]) => {
      sendMessage(
        BSON.serialize({ type: 'update', changes } satisfies UpdateRequest)
      );
    },
    [sendMessage]
  );
  const sendFrequentUpdate = useCallback(
    (change: FrequentChange) => {
      sendMessage(
        BSON.serialize({
          type: 'frequent_update',
          change,
        } satisfies FrequentUpdateRequest)
      );
    },
    [sendMessage]
  );

  const sendThrottled1 = useThrottle(sendFrequentUpdate, {
    delay: 50,
    ensure: true,
  });

  const onCursorMove = useCallback(
    (position: Position) => {
      if (wsStatus !== 'synced') {
        console.warn('not synced');
        return;
      }
      const _clientId = clientId.current;
      invariant(_clientId);
      client.current.updateFrequent(sendThrottled1, {
        path: ['clients', _clientId, 'position'],
        property: {
          value: positionLib.toString(position),
          t: Date.now(),
        },
      });
      // tick(interpolator.getInterpolatedWorldState());
    },
    [wsStatus, sendThrottled1, tick, interpolator]
  );

  useEffect(() => {
    if (wsStatus !== 'initializing') return;
    const ws = getWebSocket();
    invariant(ws && ws instanceof WebSocket);
    ws.binaryType = 'arraybuffer';
    dispatchWsStatus('initialize');
  }, [wsStatus, getWebSocket]);

  useEffect(() => {
    if (wsStatus !== 'joining') return;
    sendMessage(BSON.serialize({ type: 'join' } satisfies JoinRequest));
  }, [sendMessage, wsStatus]);

  useEffect(() => {
    if (wsStatus !== 'syncing') return;
    interpolator.reset();
    sendMessage(BSON.serialize({ type: 'sync' } satisfies SyncRequest));
  }, [interpolator, sendMessage, wsStatus]);

  const isTabActive = useIsTabActive();
  const prevIsTabActive = usePrevious(isTabActive);
  const shouldResyncNow = useMemo(
    () => prevIsTabActive !== null && !prevIsTabActive && isTabActive,
    [isTabActive, prevIsTabActive]
  );
  const [hasResyned, setHasResyned] = useState(false);

  useEffect(() => {
    if (!isTabActive) setHasResyned(false);
  }, [isTabActive]);

  useEffect(() => {
    if (shouldResyncNow) {
      console.log('RESYNCING');

      dispatchWsStatus('resync');
    }
  }, [shouldResyncNow]);

  useEffect(() => {
    if (
      wsStatus !== 'joining' &&
      wsStatus !== 'syncing' &&
      wsStatus !== 'synced'
    )
      return;
    if (!lastMessage?.data) {
      console.warn('no message');
      return;
    }
    invariant(lastMessage.data instanceof ArrayBuffer);
    const data = new Uint8Array(lastMessage.data);
    const message = BSON.deserialize(data);
    try {
      const response = RESPONSE_SCHEMA.parse(message);
      if (response.type === 'join') {
        clientId.current = response.clientId;
        dispatchWsStatus('join');
        console.log('joined as ', clientId.current);
      } else if (response.type === 'sync') {
        const state = client.current.handleSync(response);
        interpolator.reset();
        tick(new WorldState(state, []), null);
        dispatchWsStatus('sync');
        setHasResyned(true);
      } else if (response.type === 'update') {
        if (wsStatus !== 'synced') {
          console.warn('not synced');
          return;
        }
        if (!isTabActive) {
          return;
        }
        if (shouldResyncNow && !hasResyned) {
          return;
        }
        const _clientId = clientId.current;
        invariant(_clientId);

        const { state, changes } = client.current.handleUpdate(response);
        const newWorldState = new WorldState(state, changes);

        interpolator.addState(newWorldState, _clientId);
      } else if (response.type === 'clients') {
        // userPool.current = response.state?.clients as UserPool;
        setUserPool(response.state?.clients as UserPool);
      }
      // else if (response.type === "frequent_update") {
      //   console.log('FREQUENT_UPDATE');
      //   if (wsStatus !== "synced") {
      //     console.warn("not synced");
      //     return;
      //   }
      //   const _clientId = clientId.current;
      //   invariant(_clientId);
      //   const state = client.current.handleFrequentUpdate(response);
      //   interpolator.addState(new WorldState(state, new Set()));
      // }
    } catch (err) {
      console.error('err:', err);
    }
  }, [
    hasResyned,
    interpolator,
    isTabActive,
    lastMessage,
    shouldResyncNow,
    tick,
    wsStatus,
  ]);

  useKeyPress(['0', 'Escape'], () => handleChangeMode('stop_creating', true));
  useKeyPress(['1'], () => handleChangeMode('start_creating_polygon'));
  useKeyPress(['2'], () => handleChangeMode('start_creating_rectangle'));
  useKeyPress(['3'], () => handleChangeMode('start_creating_path'));
  useKeyPress(['4'], () => handleChangeMode('start_creating_boolean'));
  useKeyPress(['`'], () => {
    const ws = getWebSocket();
    ws?.close();
  });

  const replaceVertexPositionInRectangleExterior = useCallback(
    (
      featureId: string,
      vertex: RectangleVertexFeature['properties'],
      updatedPosition: Position
    ) => {
      const geom = shapesData.get(featureId);
      invariant(geom);
      invariant(geom.type === 'rectangle');
      const t = Date.now();
      const neighborH = geom.exterior.find(
        (v) => v.properties.vertexId === vertex.horizontalNeighborId
      );
      const neighborV = geom.exterior.find(
        (v) => v.properties.vertexId === vertex.verticalNeighborId
      );
      invariant(neighborH);
      invariant(neighborV);
      const changes = [
        ...mapChangeOps.rectangle.exterior.update(
          featureId,
          vertex.vertexId,
          updatedPosition,
          t
        ),
        ...mapChangeOps.rectangle.exterior.update(
          featureId,
          neighborH.properties.vertexId,
          [neighborH.geometry.coordinates[0], updatedPosition[1]],
          t
        ),
        ...mapChangeOps.rectangle.exterior.update(
          featureId,
          neighborV.properties.vertexId,
          [updatedPosition[0], neighborV.geometry.coordinates[1]],
          t
        ),
      ];
      return changes;
    },
    [shapesData]
  );

  const replaceVertexPositionInHole = useCallback(
    <T extends 'polygon' | 'rectangle'>(
      featureId: string,
      vertex: BooleanVertexFeature<T>['properties'],
      updatedPosition: Position
    ): Change[] => {
      const geom = shapesData.get(featureId);
      invariant(geom);
      invariant(geom.type === vertex.parentShapeType);
      const coords = geom.holes.get(vertex.holeId);
      invariant(coords);
      const {
        properties: { holeId, vertexId },
      } = coords[vertex.vertexIndex];
      const t = Date.now();
      const changes = mapChangeOps.hole.vertex.update(
        vertex.parentShapeType === 'polygon' ? 'polygons' : 'rectangles',
        featureId,
        holeId,
        vertexId,
        updatedPosition,
        t
      );
      return changes;
    },
    [shapesData]
  );

  const replaceVertexPositionInPath = useCallback(
    (
      featureId: string,
      vertex: PathVertexFeature['properties'],
      updatedPosition: Position
    ) => {
      const geom = shapesData.get(featureId);
      invariant(geom);
      invariant(geom.type === 'path');
      const t = Date.now();
      const changes = mapChangeOps.path.vertex.update(
        featureId,
        vertex.vertexId,
        updatedPosition,
        t
      );
      return changes;
    },
    [shapesData]
  );

  const deleteVertexInPolygonExterior = useCallback(
    (vertexToDelete: PolygonVertexFeature): Change[] => {
      const { featureId, vertexId } = vertexToDelete.properties;
      const t = Date.now();
      const geom = shapesData.get(featureId);
      invariant(geom);
      invariant(geom.type === 'polygon');
      const L = geom.exterior.length;
      invariant(L >= 3);
      if (L === 3) {
        return mapChangeOps.polygon.all.delete(featureId, t);
      } else {
        return mapChangeOps.polygon.exterior.delete(featureId, vertexId, t);
      }
    },
    [shapesData]
  );

  const deleteVertexInHole = useCallback(
    <T extends 'polygon' | 'rectangle'>(
      vertexToDelete: BooleanVertexFeature<T>
    ): Change[] => {
      const { featureId, holeId, vertexId, parentShapeType } =
        vertexToDelete.properties;
      const geom = shapesData.get(featureId);
      invariant(geom);
      invariant(geom.type === parentShapeType);
      const hole = geom.holes.get(holeId);
      invariant(hole);
      const L = hole.length;
      invariant(L >= 3);
      const t = Date.now();
      const key = parentShapeType === 'polygon' ? 'polygons' : 'rectangles';
      if (L === 3) {
        return mapChangeOps.hole.all.delete(key, featureId, holeId, t);
      } else {
        return mapChangeOps.hole.vertex.delete(
          key,
          featureId,
          holeId,
          vertexId,
          t
        );
      }
    },
    [shapesData]
  );

  const deleteVertexInPath = useCallback(
    (vertexToDelete: PathVertexFeature): Change[] => {
      const { featureId } = vertexToDelete.properties;
      const t = Date.now();
      const geom = shapesData.get(featureId);
      invariant(geom);
      invariant(geom.type === 'path');
      const L = geom.vertices.length;
      if (L === 2) {
        return mapChangeOps.path.all.delete(featureId, t);
      }
      return mapChangeOps.path.vertex.delete(
        featureId,
        vertexToDelete.properties.vertexId,
        t
      );
    },
    [shapesData]
  );

  const addVertexToPolygonExterior = useCallback(
    (midpoint: PolygonMidpointToCreateVertex) => {
      invariant(
        midpoint.before.properties.featureId ===
          midpoint.after.properties.featureId
      );
      const t = Date.now();
      const newVertexId = idLib.shortId();
      const featureId = midpoint.before.properties.featureId;
      const geom = shapesData.get(featureId);
      invariant(geom);
      invariant(geom.type === 'polygon');
      const changes = mapChangeOps.polygon.exterior.add(
        featureId,
        newVertexId,
        midpoint.before.properties.vertexId,
        midpoint.position,
        t
      );
      return changes;
    },
    [shapesData]
  );

  const addVertexToPath = useCallback(
    (midpoint: PathMidpointToCreateVertex) => {
      invariant(
        midpoint.before.properties.featureId ===
          midpoint.after.properties.featureId
      );
      const t = Date.now();
      const newVertexId = idLib.shortId();
      const { featureId } = midpoint.after.properties;
      const geom = shapesData.get(featureId);
      invariant(geom);
      invariant(geom.type === 'path');
      return mapChangeOps.path.vertex.add(
        featureId,
        newVertexId,
        midpoint.before.properties.vertexId,
        midpoint.position,
        t
      );
    },
    [shapesData]
  );

  const addVertexToHole = useCallback(
    (
      midpoint:
        | PolygonBooleanMidpointToCreateVertex
        | RectangleBooleanMidpointToCreateVertex
    ) => {
      invariant(
        midpoint.before.properties.featureId ===
          midpoint.after.properties.featureId
      );
      const t = Date.now();
      const newVertexId = idLib.shortId();
      const {
        featureId,
        holeId,
        vertexId: beforeId,
      } = midpoint.before.properties;
      const geom = shapesData.get(featureId);
      invariant(geom);
      console.log(midpoint);
      if (midpoint.parentShapeType === 'polygon') {
        return mapChangeOps.hole.vertex.add(
          'polygons',
          featureId,
          newVertexId,
          beforeId,
          midpoint.position,
          holeId,
          t
        );
      } else if (midpoint.parentShapeType === 'rectangle') {
        return mapChangeOps.hole.vertex.add(
          'rectangles',
          featureId,
          newVertexId,
          beforeId,
          midpoint.position,
          holeId,
          t
        );
      } else {
        invariant(false, 'unsupported parent shape type');
      }
    },
    [shapesData]
  );

  const handleChangeMode = useCallback(
    (action: ModeAction, allowRedundant = false) => {
      const _clientId = clientId.current;
      if (!_clientId) return;

      // if this would take us to the state we're already in, do nothing
      if (!allowRedundant && isRedundantModeAction(action)) return;

      // delete tentative
      client.current.update(
        sendUpdate,
        mapChangeOps.tentative.delete(_clientId, Date.now())
      );
      // tick(interpolator.getInterpolatedWorldState());

      // change the mode
      dispatchMode(action);

      // drop next vertex id
      setNextVertexId(null);

      // deactivate any shapes
      deactivateAllShapes();
    },
    [deactivateAllShapes, interpolator, isRedundantModeAction, sendUpdate, tick]
  );

  const onClickAddPolygonVertexHandle = useCallback(
    (midpoint: PolygonMidpointToCreateVertex) => {
      client.current.update(sendUpdate, [
        ...addVertexToPolygonExterior(midpoint),
      ]);
      // tick(interpolator.getInterpolatedWorldState());
      setMidpointToCreateVertex(null);
    },
    [addVertexToPolygonExterior, interpolator, sendUpdate, tick]
  );

  const onClickAddPathVertexHandle = useCallback(
    (midpoint: PathMidpointToCreateVertex) => {
      client.current.update(sendUpdate, [...addVertexToPath(midpoint)]);
      // tick(interpolator.getInterpolatedWorldState());
      setMidpointToCreateVertex(null);
    },
    [addVertexToPath, interpolator, sendUpdate, tick]
  );

  const onClickAddBooleanVertexHandle = useCallback(
    (
      midpoint:
        | PolygonBooleanMidpointToCreateVertex
        | RectangleBooleanMidpointToCreateVertex
    ) => {
      client.current.update(sendUpdate, [...addVertexToHole(midpoint)]);
      // tick(interpolator.getInterpolatedWorldState());
      setMidpointToCreateVertex(null);
    },
    [addVertexToHole, interpolator, sendUpdate, tick]
  );

  const onClickDeletePolygonVertexHandle = useCallback(
    (vertex: PolygonVertexFeature) => {
      // delete vertex
      client.current.update(sendUpdate, [
        ...deleteVertexInPolygonExterior(vertex),
      ]);
      // tick(interpolator.getInterpolatedWorldState());
      setVertexToDelete(null);
    },
    [deleteVertexInPolygonExterior, interpolator, sendUpdate, tick]
  );

  const onClickDeletePathVertexHandle = useCallback(
    (vertex: PathVertexFeature) => {
      // delete vertex
      client.current.update(sendUpdate, [...deleteVertexInPath(vertex)]);
      // tick(interpolator.getInterpolatedWorldState());
      setVertexToDelete(null);
    },
    [deleteVertexInPath, interpolator, sendUpdate, tick]
  );

  const onClickDeleteRectangleHoleVertexHandle = useCallback(
    (vertex: BooleanVertexFeature<'rectangle'>) => {
      // delete vertex
      client.current.update(sendUpdate, [...deleteVertexInHole(vertex)]);
      // tick(interpolator.getInterpolatedWorldState());
      setVertexToDelete(null);
    },
    [deleteVertexInHole, interpolator, sendUpdate, tick]
  );

  const onClickDeletePolygonHoleVertexHandle = useCallback(
    (vertex: BooleanVertexFeature<'polygon'>) => {
      // delete vertex
      client.current.update(sendUpdate, [...deleteVertexInHole(vertex)]);
      // tick(interpolator.getInterpolatedWorldState());
      setVertexToDelete(null);
    },
    [deleteVertexInHole, interpolator, sendUpdate, tick]
  );

  const finishTentativeShape = useCallback(
    (shapeId: string) => {
      setNextVertexId(null);
      handleChangeMode('stop_creating');
      activateShape(shapeId);
    },
    [activateShape, handleChangeMode]
  );

  /**
   * ASSUMES: current tentative shape is a polygon
   */
  const onClickTentativePolygonVertex = useCallback(
    (_clientId: string) => {
      invariant(mode === 'creating_polygon');

      // finish tentative sequence, create shape
      const tentative = tentativeSequencesData.get(_clientId);
      invariant(tentative);
      const tentativeShape = tentative.shape;

      if (tentativeShape) {
        invariant(tentativeShape.type === 'polygon');
        const tentativeSequence = Object.entries(tentativeShape.vertices);
        if (tentativeSequence.length < 3) return;
        const newShapeId = idLib.shortId();
        const t = Date.now();
        client.current.update(
          sendUpdate,
          [
            // delete tentative
            ...mapChangeOps.tentative.delete(_clientId, t),

            // create shape
            ...tentativeSequence.map(([vId, v], i): Change => {
              const property = {
                value: positionLib.toString(v.geometry.coordinates),
                t,
              };
              const path = ['polygons', newShapeId, 'exterior', vId];
              if (i === 0) {
                return changeOps.head(path, property);
              } else {
                return changeOps.insert(
                  path,
                  property,
                  tentativeSequence[i - 1][0]
                );
              }
            }),
          ],
          { log: true }
        );
        // tick(interpolator.getInterpolatedWorldState());
        finishTentativeShape(newShapeId);
      }
    },
    [
      finishTentativeShape,
      interpolator,
      mode,
      sendUpdate,
      tentativeSequencesData,
      tick,
    ]
  );

  const onClickTentativePathVertex = useCallback(
    (_clientId: string) => {
      invariant(mode === 'creating_path');

      // finish tentative sequence, create shape
      const tentative = tentativeSequencesData.get(_clientId);
      invariant(tentative);
      const shape = tentative.shape;
      invariant(shape && shape.type === 'path');
      const t = Date.now();
      const newShapeId = idLib.shortId();
      client.current.update(sendUpdate, [
        // delete tentative
        ...mapChangeOps.tentative.delete(_clientId, Date.now()),

        // create shape
        ...shape.vertices.map(
          (
            { geometry: { coordinates }, properties: { vertexId } },
            i
          ): Change => {
            const property = { value: positionLib.toString(coordinates), t };
            const path = ['paths', newShapeId, vertexId];
            if (i === 0) {
              return changeOps.head(path, property);
            } else {
              return changeOps.insert(
                path,
                property,
                shape.vertices[i - 1].properties.vertexId
              );
            }
          }
        ),
      ]);
      // tick(interpolator.getInterpolatedWorldState());
      finishTentativeShape(newShapeId);
    },
    [
      finishTentativeShape,
      interpolator,
      mode,
      sendUpdate,
      tentativeSequencesData,
      tick,
    ]
  );

  const onClickTentativeBooleanVertex = useCallback(
    (_clientId: string) => {
      invariant(mode === 'creating_boolean');

      const tentative = tentativeSequencesData.get(_clientId);
      invariant(tentative);
      const tentativeShape = tentative.shape;
      invariant(tentativeShape && tentativeShape.type === 'boolean');
      const parent = shapesData.get(tentativeShape.parentShapeId);
      invariant(parent);
      invariant(
        parent.type === 'polygon' || parent.type === 'rectangle',
        `unsupported parent type ${parent.type}`
      );

      const shapeKey = parent.type === 'polygon' ? 'polygons' : 'rectangles';
      const vertices = Object.values(tentativeShape.vertices);
      if (vertices.length < 3) return;
      const holeId = idLib.shortId();

      const t = Date.now();

      const changes = mapChangeOps.tentative.delete(_clientId, Date.now()); // delete tentative
      changes.push(
        ...vertices.map(
          (
            { geometry: { coordinates }, properties: { vertexId } },
            i
          ): Change => {
            const id = idLib.shortId();
            const path = [
              shapeKey,
              tentativeShape.parentShapeId,
              'holes',
              holeId,
              vertexId,
            ];
            if (i === 0) {
              return {
                id,
                operation: 'head',
                path,
                property: { value: positionLib.toString(coordinates), t },
              };
            } else {
              return {
                id,
                operation: 'insert',
                path,
                property: { value: positionLib.toString(coordinates), t },
                prevKey: vertices[i - 1].properties.vertexId,
              };
            }
          }
        )
      );

      client.current.update(sendUpdate, changes, { log: true });

      // tick(interpolator.getInterpolatedWorldState());
      finishTentativeShape(tentativeShape.parentShapeId);
    },
    [
      finishTentativeShape,
      interpolator,
      mode,
      sendUpdate,
      shapesData,
      tentativeSequencesData,
      tick,
    ]
  );

  const onClickOnMap = useCallback(
    (_clientId: string, coordinate: Position) => {
      setVertexToDelete(null);
      setMidpointToCreateVertex(null);

      if (!isModeCreating(mode)) return;

      const tentative = tentativeSequencesData.get(_clientId);
      const lengthBefore = tentative?.sequenceIds.length ?? 0;

      // we could be in "creating_boolean" mode without a tentative shape existing, meaning no parent shape has been clicked on
      if (mode === 'creating_boolean') {
        if (!tentative?.shape) return;
        invariant(
          tentative.shape.type === 'boolean',
          'tentative shape not a boolean'
        );
      }

      // if we're currently creating a rectangle and have already placed one vertex down, create the rectangle shape
      if (mode === 'creating_rectangle') {
        if (lengthBefore > 0) {
          if (!tentative?.shape) return;
          invariant(tentative.shape.type === 'rectangle');
          invariant(tentative.shape.final);

          // complete the shape
          const t = Date.now();
          const newShapeId = idLib.shortId();
          client.current.update(sendUpdate, [
            // delete tentative
            ...mapChangeOps.tentative.delete(_clientId, Date.now()),

            // create the shape
            ...[
              tentative.shape.initial,
              tentative.shape.final,
              ...Object.values(tentative.shape.derived),
            ].flatMap(
              ({
                geometry: { coordinates },
                properties: { vertexId, corner },
              }): Change[] => {
                return [
                  {
                    id: idLib.shortId(),
                    operation: 'set',
                    path: [
                      'rectangles',
                      newShapeId,
                      'exterior',
                      vertexId,
                      'position',
                    ],
                    property: { value: positionLib.toString(coordinates), t },
                  },
                  {
                    id: idLib.shortId(),
                    operation: 'set',
                    path: [
                      'rectangles',
                      newShapeId,
                      'exterior',
                      vertexId,
                      'corner',
                    ],
                    property: { value: rectangleLib.cornerToString(corner), t },
                  },
                ];
              }
            ),
          ]);
          // tick(interpolator.getInterpolatedWorldState());
          finishTentativeShape(newShapeId);
          return;
        }
      }

      // otherwise, create a tentative vertex
      const vertexId = nextVertexId ?? idLib.shortId();
      const t = Date.now();
      const shapeType = creatingModeToTentativeShapeType(mode);
      client.current.update(sendUpdate, [
        {
          id: idLib.shortId(),
          operation: 'set',
          path: ['clients', _clientId, 'tentativeSequence', vertexId],
          property: {
            value: vertexLib.toString({
              position: coordinate,
              positionIndices: [lengthBefore, 0],
            }),
            t,
          },
        },
        {
          id: idLib.shortId(),
          operation: 'set',
          path: ['clients', _clientId, 'tentativeType'],
          property: { value: shapeType, t },
        },
      ]);
      // tick(interpolator.getInterpolatedWorldState());

      const newVertexId = idLib.shortId();
      setNextVertexId(newVertexId);

      // delete popups
      setVertexToDelete(null);
      setMidpointToCreateVertex(null);
    },
    [
      finishTentativeShape,
      interpolator,
      mode,
      nextVertexId,
      sendUpdate,
      tentativeSequencesData,
      tick,
    ]
  );

  /**
   * Returns the newly active shape ID, if applicable.
   */
  const onClickInsideShape = useCallback(
    (
      _clientId: string,
      coordinate: Position,
      parentShapeId: string,
      mapShapeType: 'polygon' | 'rectangle' | 'path'
    ) => {
      if (mode === 'creating_boolean') {
        if (mapShapeType === 'path') {
          // TODO: BUG - can't click on path while creating boolean in other shape
          return;
        }

        const shape = shapesData.get(parentShapeId);
        invariant(shape);
        invariant(shape.type === mapShapeType, `got ${shape.type}`);

        const vertexId = nextVertexId ?? idLib.shortId();
        const t = Date.now();

        const tentative = tentativeSequencesData.get(_clientId);
        const lengthBefore = tentative?.sequenceIds.length ?? 0;

        const changes: Change[] = [
          changeOps.set(['clients', _clientId, 'tentativeSequence', vertexId], {
            value: vertexLib.toString({
              position: coordinate,
              positionIndices: [lengthBefore, 0],
            }),
            t,
          }),
          changeOps.set(['clients', _clientId, 'tentativeType'], {
            value: SHAPE_TYPE.boolean,
            t,
          }),
        ];
        if (!tentative) {
          // TODO: accept a NONE value for when no tentative parent is chosen
          changes.push(
            changeOps.set(['clients', _clientId, 'tentativeParentShapeId'], {
              value: parentShapeId,
              t,
            })
          );
        }
        client.current.update(sendUpdate, changes);

        // tick(interpolator.getInterpolatedWorldState());
        const newVertexId = idLib.shortId();
        setNextVertexId(newVertexId);
      } else {
        if (isModeCreating(mode)) {
          onClickOnMap(_clientId, coordinate);
        } else {
          invariant(
            shapesData.has(parentShapeId),
            `shape not found: ${parentShapeId}`
          );
          activateShape(parentShapeId);
          // TODO: BUG??
          setHoverState('active');
        }
      }

      // delete popups
      setVertexToDelete(null);
      setMidpointToCreateVertex(null);
    },
    [
      activateShape,
      interpolator,
      mode,
      nextVertexId,
      onClickOnMap,
      sendUpdate,
      shapesData,
      tentativeSequencesData,
      tick,
    ]
  );

  const getClosestActiveShapeVertex = useCallback(
    (target: Position) => {
      const targetXy = PROJECTION.forward(target);
      let current: {
        vertex:
          | PolygonVertexFeature
          | PathVertexFeature
          | BooleanVertexFeature<'rectangle'>
          | BooleanVertexFeature<'polygon'>;
        xyDistance: number;
      } | null = null;
      for (const [, group] of [...shapesData].filter(([sId]) =>
        activeShapeIds.has(sId)
      )) {
        if (group.type === 'polygon') {
          for (const vertex of group.exterior) {
            const positionXy = PROJECTION.forward(vertex.geometry.coordinates);
            const xyDistance = geometryLib.squaredDistance(
              targetXy,
              positionXy
            );
            if (!current || xyDistance < current.xyDistance) {
              current = { vertex, xyDistance };
            }
          }
          for (const [, hole] of group.holes) {
            if (hole) {
              for (const vertex of hole) {
                const positionXy = PROJECTION.forward(
                  vertex.geometry.coordinates
                );
                const xyDistance = geometryLib.squaredDistance(
                  targetXy,
                  positionXy
                );
                if (!current || xyDistance < current.xyDistance) {
                  current = { vertex, xyDistance };
                }
              }
            }
          }
        } else if (group.type === 'path') {
          for (const vertex of group.vertices) {
            const positionXy = PROJECTION.forward(vertex.geometry.coordinates);
            const xyDistance = geometryLib.squaredDistance(
              targetXy,
              positionXy
            );
            if (!current || xyDistance < current.xyDistance) {
              current = { vertex, xyDistance };
            }
          }
        } else if (group.type === 'rectangle') {
          for (const [, hole] of group.holes) {
            if (hole) {
              for (const vertex of hole) {
                const positionXy = PROJECTION.forward(
                  vertex.geometry.coordinates
                );
                const xyDistance = geometryLib.squaredDistance(
                  targetXy,
                  positionXy
                );
                if (!current || xyDistance < current.xyDistance) {
                  current = { vertex, xyDistance };
                }
              }
            }
          }
        }
      }
      return current;
    },
    [activeShapeIds, shapesData]
  );

  const getClosestActiveShapeMidpoint = useCallback(
    (target: Position): MidpointToCreateVertex | null => {
      const project = PROJECTION.forward;
      const unproject = PROJECTION.inverse;

      const targetXy = project(target);
      let current: MidpointToCreateVertex | null = null;
      for (const [, shape] of [...shapesData].filter((s) =>
        activeShapeIds.has(s[0])
      )) {
        if (shape.type === 'polygon') {
          for (let j = 0; j < shape.exterior.length; j++) {
            const before = shape.exterior[j];
            const after = shape.exterior[(j + 1) % shape.exterior.length];
            const midpointXy = geometryLib.midpoint(
              project(before.geometry.coordinates),
              project(after.geometry.coordinates)
            );
            const xyDistance = geometryLib.squaredDistance(
              targetXy,
              midpointXy
            );
            if (!current || xyDistance < current.xyDistance) {
              const position = unproject(midpointXy);
              current = {
                shapeType: 'polygon',
                before,
                after,
                position,
                xyDistance,
              };
            }
          }

          for (const [, ring] of shape.holes) {
            for (let j = 0; j < ring.length; j++) {
              const before = ring[j];
              const after = ring[(j + 1) % ring.length];
              const midpointXy = geometryLib.midpoint(
                project(before.geometry.coordinates),
                project(after.geometry.coordinates)
              );
              const xyDistance = geometryLib.squaredDistance(
                targetXy,
                midpointXy
              );
              if (!current || xyDistance < current.xyDistance) {
                const position = unproject(midpointXy);
                current = {
                  shapeType: 'boolean',
                  parentShapeType: 'polygon',
                  before,
                  after,
                  position,
                  xyDistance,
                };
              }
            }
          }
        } else if (shape.type === 'path') {
          for (let j = 0; j < shape.vertices.length - 1; j++) {
            const before = shape.vertices[j];
            const after = shape.vertices[j + 1];
            const midpointXy = geometryLib.midpoint(
              project(before.geometry.coordinates),
              project(after.geometry.coordinates)
            );
            const xyDistance = geometryLib.squaredDistance(
              targetXy,
              midpointXy
            );
            if (!current || xyDistance < current.xyDistance) {
              const position = unproject(midpointXy);
              current = {
                shapeType: 'path',
                before,
                after,
                position,
                xyDistance,
              };
            }
          }
        } else if (shape.type === 'rectangle') {
          for (const [, ringNoLoop] of shape.holes) {
            const ring = [...ringNoLoop, ringNoLoop[0]];
            for (let j = 0; j < ring.length - 1; j++) {
              const before = ring[j];
              const after = ring[j + 1];
              const midpointXy = geometryLib.midpoint(
                project(before.geometry.coordinates),
                project(after.geometry.coordinates)
              );
              const xyDistance = geometryLib.squaredDistance(
                targetXy,
                midpointXy
              );
              if (!current || xyDistance < current.xyDistance) {
                const position = unproject(midpointXy);
                current = {
                  shapeType: 'boolean',
                  parentShapeType: 'rectangle',
                  before,
                  after,
                  position,
                  xyDistance,
                };
              }
            }
          }
        }
      }
      return current;
    },
    [activeShapeIds, shapesData]
  );

  const updateHoverState = useCallback(
    (info: PickingInfoT) => {
      let _state: typeof hoverState = 'map';
      if (info.handle) {
        _state = 'handle';
      } else if (info.shape) {
        if (activeShapeIds.has(info.shape.id)) {
          _state = 'active';
        } else {
          _state = 'inactive';
        }
      }
      setHoverState(_state);
    },
    [activeShapeIds]
  );

  const handleChangeDataType = useCallback(
    (dataType: string) => {
      if (!activeShapeIds.size) return;
      const t = Date.now();
      const changes = [...activeShapeIds].flatMap((id) => {
        const shape = shapesData.get(id);
        invariant(shape, `active shape not found: ${id}`);
        if (isDerivedDataType(dataType)) {
          const newShapeId = idLib.shortId();
          const baseDataType = getBaseDataType(dataType);
          const changes: Change[] = [];
          switch (shape.type) {
            case 'polygon':
              changes.push(
                ...copyPolygon(shape, newShapeId, t),
                ...mapChangeOps.dataType.change(
                  'polygons',
                  baseDataType,
                  id,
                  t
                ),
                ...mapChangeOps.dataType.changeDerived(
                  'polygons',
                  newShapeId,
                  id,
                  t
                )
              );
              break;
            case 'rectangle':
              changes.push(
                ...copyRectangle(shape, newShapeId, t),
                ...mapChangeOps.dataType.change(
                  'rectangles',
                  baseDataType,
                  id,
                  t
                ),
                ...mapChangeOps.dataType.changeDerived(
                  'rectangles',
                  newShapeId,
                  id,
                  t
                )
              );
              break;
            case 'path':
              changes.push(
                ...copyPath(shape, newShapeId, t),
                ...mapChangeOps.dataType.change('paths', baseDataType, id, t),
                ...mapChangeOps.dataType.changeDerived(
                  'paths',
                  newShapeId,
                  id,
                  t
                )
              );
              break;
          }
          activateShape(newShapeId);
          return changes;
        } else {
          return mapChangeOps.dataType.change(
            shape.type === 'polygon'
              ? 'polygons'
              : shape.type === 'rectangle'
                ? 'rectangles'
                : 'paths',
            dataType,
            id,
            t
          );
        }
      });
      client.current.update(sendUpdate, changes, { log: true });
      // tick(interpolator.getInterpolatedWorldState());
    },
    [activateShape, activeShapeIds, interpolator, sendUpdate, shapesData, tick]
  );

  const shapeLayerData = useMemo(
    () => ({
      shapes: shapesData,
      tentativeShapes: tentativeSequencesData,
      activeShapeIds,
    }),
    [activeShapeIds, shapesData, tentativeSequencesData]
  );

  const shapeBoundsByDataType = useMemo(
    () =>
      Object.fromEntries(
        DATA_TYPES.map((dataType) => [
          dataType,
          shapeBounds
            .filter(
              (shape) =>
                (hoveredDataType &&
                  dataType === hoveredDataType &&
                  activeShapeIds.has(shape.properties.shapeId)) ||
                shape.properties.dataType === dataType
            )
            .map((l) => ({
              ...l,
              properties: { ...l.properties, dataType },
            })),
        ])
      ),
    [activeShapeIds, hoveredDataType, shapeBounds]
  );
  const shapeBoundsByDerivedDataType = useMemo(() => {
    const shapesById = new Map(
      shapeBounds.map((s) => [s.properties.shapeId, s])
    );
    const shapeIdToBase = new Map(
      shapeBounds
        .filter((s) => s.properties.baseShapeId)
        .map((s) => [
          s.properties.shapeId,
          shapesById.get(s.properties.baseShapeId!),
        ])
    );
    return Object.fromEntries(
      [...DERIVED_DATA_TYPES.exterior, ...DERIVED_DATA_TYPES.interior].map(
        (dataType) => {
          const baseDataType = getBaseDataType(dataType);
          return [
            dataType,
            shapeBounds.filter((s) => {
              if (!s.properties.baseShapeId) return false;
              const baseShape = shapeIdToBase.get(s.properties.shapeId);
              if (!baseShape) return false;
              return baseShape.properties.dataType === baseDataType;
            }),
          ];
        }
      )
    );
  }, [shapeBounds]);
  const coverageLayersByDataType = useMemo(() => {
    return Object.fromEntries(
      DATA_TYPES.map((dataType) => {
        return [
          dataType,
          coverageLayerGeometries
            .filter((l) => l.category_name === dataType)
            .map((l) =>
              turf.feature(l.geometry, { dataType, isFull: l.is_full })
            ),
        ];
      })
    );
  }, [coverageLayerGeometries]);
  const coverageLabels = useMemo(() => {
    return coverageLayerLabels.reduce(
      (acc, layer) => {
        return acc.concat(
          layer.label_positions.coordinates.map((p, i) => ({
            position: p,
            text: dateFns.format(new Date(layer.acquired_at), 'MMM yyyy'),
            priority: layer.label_priorities[i],
            dataType: layer.category_name,
          }))
        );
      },
      [] as {
        position: Position;
        text: string;
        priority: number;
        dataType: string;
      }[]
    );
  }, [coverageLayerLabels]);
  const coverageLabelsByDataType = useMemo(() => {
    return Object.fromEntries(
      DATA_TYPES.map((dataType) => {
        return [
          dataType,
          coverageLabels.filter((l) => l.dataType === dataType),
        ];
      })
    );
  }, [coverageLabels]);

  const coverageLayers = useMemo(
    () =>
      [...DATA_TYPES, ...Object.values(DERIVED_DATA_TYPES).flat()].flatMap(
        (dataType) => {
          const geofenceId = `geofence-${dataType}`;
          const layerProps: Partial<
            GeoJsonLayerProps<{ dataType: string; baseShapeId?: string }>
          > = {
            id: `coverage-${dataType}`,
            stroked: true,
            getLineWidth: 1,
            filled: true,
            getFillColor: (feat) => {
              let dataType = feat.properties.dataType;
              if (feat.properties.baseShapeId) {
                const baseShape = shapeBounds.find(
                  (s) => s.properties.shapeId === feat.properties.baseShapeId
                );
                if (baseShape) {
                  dataType = baseShape.properties.dataType;
                }
              }
              const color =
                DATA_TYPE_TO_COLOR[dataType as keyof typeof DATA_TYPE_TO_COLOR];
              if (!color)
                return [...DATA_TYPE_TO_COLOR.draft, 128] satisfies Rgba;
              return [...color, 128] satisfies Rgba;
            },
            getLineColor: (feat) => {
              let dataType = feat.properties.dataType;
              if (feat.properties.baseShapeId) {
                const baseShape = shapeBounds.find(
                  (s) => s.properties.shapeId === feat.properties.baseShapeId
                );
                if (baseShape) {
                  dataType = baseShape.properties.dataType;
                }
              }
              const color =
                DATA_TYPE_TO_COLOR[dataType as keyof typeof DATA_TYPE_TO_COLOR];
              if (!color)
                return [...DATA_TYPE_TO_COLOR.draft, 255] satisfies Rgba;
              return [...color, 255] satisfies Rgba;
            },
          };

          if (DATA_TYPES.some((t) => t === dataType)) {
            const layers = coverageLayersByDataType[dataType];
            const masking = layers.some((f) => !f.properties.isFull);
            return [
              ...(masking
                ? [
                    new GeoJsonLayer({
                      id: geofenceId,
                      data: shapeBoundsByDataType[dataType],
                      operation: 'mask',
                    }),
                    new GeoJsonLayer({
                      data: layers,
                      extensions: [new MaskExtension()],
                      maskId: geofenceId,
                      ...layerProps,
                    }),
                    new TextLayer<
                      (typeof coverageLabelsByDataType)[typeof dataType][number]
                    >({
                      id: `coverage_labels-${dataType}`,
                      data: coverageLabelsByDataType[dataType],
                      getPosition: (label) =>
                        label.position as [number, number],
                      getText: (label) => label.text,
                      fontWeight: 'bold',
                      getSize: 12,
                      extensions: [
                        new MaskExtension(),
                        new CollisionFilterExtension(),
                      ],
                      // @ts-expect-error unknown property
                      collisionTestProps: { sizeScale: 3 },
                      getCollisionPriority: (
                        label: (typeof coverageLabelsByDataType)[typeof dataType][number]
                      ) => label.priority,
                      maskId: geofenceId,
                      maskByInstance: true,
                      background: true,
                      getBackgroundColor: (label) => {
                        const color =
                          DATA_TYPE_TO_COLOR[
                            label.dataType as keyof typeof DATA_TYPE_TO_COLOR
                          ];
                        if (!color)
                          return [
                            ...DATA_TYPE_TO_COLOR.draft,
                            255,
                          ] satisfies Rgba;
                        return [...color, 255] satisfies Rgba;
                      },
                      getColor: [255, 255, 255, 255],
                      backgroundPadding: [2, 2],
                    }),
                  ]
                : [
                    new GeoJsonLayer({
                      data: shapeBoundsByDataType[dataType],
                      ...layerProps,
                    }),
                  ]),
            ];
          } else {
            const derivedShapes = shapeBoundsByDerivedDataType[dataType];
            if (!derivedShapes || derivedShapes.length === 0) return [];
            const layers = derivedShapes
              .map((shape) => {
                if (!shape.properties.baseShapeId) {
                  console.warn('no base shape id', shape);
                  return null;
                }
                const baseShape = shapeBounds.find(
                  (s) => s.properties.shapeId === shape.properties.baseShapeId
                );
                if (!baseShape) return null;
                const intersect = turf.intersect(
                  turf.featureCollection([baseShape, shape]),
                  { properties: { baseShapeId: shape.properties.baseShapeId } }
                );
                if (!intersect) return null;
                return intersect;
              })
              .filter(arrayLib.filterNonNull);
            return [
              new GeoJsonLayer({
                data: layers,
                ...layerProps,
              }),
            ];
          }
        }
      ),
    [
      coverageLabelsByDataType,
      coverageLayersByDataType,
      shapeBounds,
      shapeBoundsByDataType,
      shapeBoundsByDerivedDataType,
    ]
  );

  const activeShape = useMemo(() => {
    if (activeShapeIds.size === 1) {
      return shapesData.get([...activeShapeIds.values()][0]);
    }
  }, [activeShapeIds, shapesData]);

  return (
    <DeckGL
      layers={[
        new ShapeLayer({
          id: 'shape-layer',
          data: shapeLayerData,
        }),
        ...coverageLayers,
        new ShapeHandleLayer({
          id: 'shape-handle-layer',
          data: shapeLayerData,
        }),
        new DeleteVertexLayer({
          data: vertexToDelete
            ? [{ type: 'delete-vertex', vertex: vertexToDelete }]
            : [],
        }),
        new AddVertexLayer({
          data: midpointToCreateVertex
            ? [{ type: 'add-vertex', midpoint: midpointToCreateVertex }]
            : [],
        }),

        new CursorLayer({
          id: 'cursor-layer',
          clientId: clientId.current,
          data: interpolatedPositions,
          userPool,
        }),
      ]}
      initialViewState={{
        longitude: INITIAL_MAP_OPTIONS.CENTER[0],
        latitude: INITIAL_MAP_OPTIONS.CENTER[1],
        zoom: INITIAL_MAP_OPTIONS.ZOOM,
      }}
      onViewStateChange={(params) => {
        visGm.limitTiltRange(params);
      }}
      controller={{
        doubleClickZoom: false, // fixes delay
        scrollZoom: {
          smooth: true,
          speed: 0.005,
        },
        dragRotate: false,
        touchRotate: false,
      }}
      // useDevicePixels={false} // https://deck.gl/docs/developer-guide/performance#common-issues
      onDragStart={(info: PickingInfoT) => {
        const _clientId = clientId.current;
        if (!_clientId) return;
        if (info.handle && info.handle.type === 'shape') {
          const t = Date.now();
          client.current.update(sendUpdate, [
            changeOps.set(['clients', _clientId, 'draggingShapeVertexId'], {
              value: info.handle.vertexId,
              t,
            }),
          ]);
        }
        setVertexToDelete(null);
        setMidpointToCreateVertex(null);
      }}
      onDrag={(info: PickingInfoT, event) => {
        const _clientId = clientId.current;
        if (!_clientId) return;
        const { coordinate } = info;
        if (coordinate) {
          if (info.handle) {
            if (info.handle.type === 'shape') {
              event.stopImmediatePropagation();
              onCursorMove(coordinate);
            }
          }
        }
      }}
      onDragEnd={(info: PickingInfoT) => {
        const _clientId = clientId.current;
        if (!_clientId) return;
        const { coordinate } = info;
        if (coordinate) {
          if (info.handle) {
            if (info.handle.type === 'shape') {
              if (info.handle.shapeType === 'polygon') {
                const t = Date.now();
                client.current.update(sendUpdate, [
                  ...mapChangeOps.polygon.exterior.update(
                    info.handle.featureId,
                    info.handle.vertexId,
                    coordinate,
                    t
                  ),
                  changeOps.set(
                    ['clients', _clientId, 'draggingShapeVertexId'],
                    { value: 'NONE', t }
                  ),
                ]);
                // tick(interpolator.getInterpolatedWorldState());
              } else if (info.handle.shapeType === 'rectangle') {
                const t = Date.now();
                client.current.update(
                  sendUpdate,
                  [
                    ...replaceVertexPositionInRectangleExterior(
                      info.handle.featureId,
                      info.handle,
                      coordinate
                    ),
                    changeOps.set(
                      ['clients', _clientId, 'draggingShapeVertexId'],
                      { value: 'NONE', t }
                    ),
                  ],
                  { log: true }
                );
                // tick(interpolator.getInterpolatedWorldState());
              } else if (info.handle.shapeType === 'boolean') {
                const t = Date.now();
                client.current.update(sendUpdate, [
                  ...replaceVertexPositionInHole(
                    info.handle.featureId,
                    info.handle,
                    coordinate
                  ),
                  changeOps.set(
                    ['clients', _clientId, 'draggingShapeVertexId'],
                    { value: 'NONE', t }
                  ),
                ]);
                // tick(interpolator.getInterpolatedWorldState());
              } else if (info.handle.shapeType === 'path') {
                const t = Date.now();
                client.current.update(sendUpdate, [
                  ...replaceVertexPositionInPath(
                    info.handle.featureId,
                    info.handle,
                    coordinate
                  ),
                  changeOps.set(
                    ['clients', _clientId, 'draggingShapeVertexId'],
                    { value: 'NONE', t }
                  ),
                ]);
                // tick(interpolator.getInterpolatedWorldState());
              }
            }
          }
        }
      }}
      onClick={(info: PickingInfoT) => {
        const _clientId = clientId.current;
        if (!_clientId) return;
        if (info.shape) {
          if (!info.coordinate) return;
          if (
            info.shape.type === 'polygon' ||
            info.shape.type === 'rectangle' ||
            info.shape.type === 'path'
          ) {
            onClickInsideShape(
              _clientId,
              info.coordinate,
              info.shape.id,
              info.shape.type
            );
          }
        } else if (info.handle) {
          if (info.handle.type === 'add-vertex') {
            if (info.handle.midpoint.shapeType === 'polygon') {
              onClickAddPolygonVertexHandle(info.handle.midpoint);
            } else if (info.handle.midpoint.shapeType === 'path') {
              onClickAddPathVertexHandle(info.handle.midpoint);
            } else if (info.handle.midpoint.shapeType === 'boolean') {
              onClickAddBooleanVertexHandle(info.handle.midpoint);
            }
          } else if (info.handle.type === 'delete-vertex') {
            const { vertex } = info.handle;
            if (vertex.properties.shapeType === 'polygon') {
              onClickDeletePolygonVertexHandle(vertex as PolygonVertexFeature);
            } else if (vertex.properties.shapeType === 'path') {
              onClickDeletePathVertexHandle(vertex as PathVertexFeature);
            } else if (vertex.properties.shapeType === 'boolean') {
              if (vertex.properties.parentShapeType === 'rectangle') {
                onClickDeleteRectangleHoleVertexHandle(
                  vertex as BooleanVertexFeature<'rectangle'>
                );
              } else {
                invariant(vertex.properties.parentShapeType === 'polygon');
                onClickDeletePolygonHoleVertexHandle(
                  vertex as BooleanVertexFeature<'polygon'>
                );
              }
            }
          } else if (info.handle.type === 'tentative') {
            if (info.handle.shapeType === 'polygon') {
              onClickTentativePolygonVertex(_clientId);
            } else if (info.handle.shapeType === 'path') {
              onClickTentativePathVertex(_clientId);
            } else if (info.handle.shapeType === 'boolean') {
              onClickTentativeBooleanVertex(_clientId);
            }
          }
        } else {
          if (info.coordinate) {
            onClickOnMap(_clientId, info.coordinate);
          }
        }
      }}
      _onMetrics={(metrics) => {
        updateMetrics(metrics);
      }}
      getCursor={(state) => {
        if (hoverState === 'active') {
          return 'pointer';
        } else if (hoverState === 'inactive') {
          return 'pointer';
        } else if (hoverState === 'handle') {
          return 'pointer';
        }
        if (state.isDragging) {
          return 'grabbing';
        }
        return 'grab';
      }}
      onHover={(info: PickingInfoT) => {
        const _clientId = clientId.current;
        if (!_clientId) {
          console.warn('no client id');
          return;
        }

        // if onHover is called while we are currently dragging a vertex
        //  that means we have dragged the vertex outside of the window (so onDragEnd was skipped)
        //  in this case, we need to stop dragging
        if (clientIdToDraggingVertexIds.has(_clientId)) {
          const t = Date.now();
          client.current.update(sendUpdate, [
            changeOps.set(['clients', _clientId, 'draggingShapeVertexId'], {
              value: 'NONE',
              t,
            }),
          ]);
          // tick(interpolator.getInterpolatedWorldState());
        }

        const { coordinate } = info;
        if (!coordinate) {
          console.warn('no coordinate');
          return;
        }
        // relay cursor position info to other clients
        onCursorMove(coordinate);

        // handle showing/hiding of vertex/midpoint popups
        if (!nextVertexId) {
          // if we're already hovering on a handle, change nothing
          if (!info.handle) {
            // we should show the popup that has a smaller distance
            let show:
              | {
                  type: 'delete';
                  vertex:
                    | PolygonVertexFeature
                    | PathVertexFeature
                    | BooleanVertexFeature<'rectangle'>
                    | BooleanVertexFeature<'polygon'>;
                  xyDistance: number;
                }
              | { type: 'add'; midpoint: MidpointToCreateVertex }
              | null = null;

            const closest = getClosestActiveShapeVertex(coordinate);
            if (
              closest &&
              closest.xyDistance <= MIN_DISTANCE_TO_DELETE_VERTEX_PIXELS
            ) {
              show = {
                type: 'delete',
                vertex: closest.vertex,
                xyDistance: closest.xyDistance,
              };
            }

            const midpoint = getClosestActiveShapeMidpoint(coordinate);
            if (
              midpoint &&
              midpoint.xyDistance <= MIN_DISTANCE_TO_CREATE_VERTEX_PIXELS &&
              (!show || midpoint.xyDistance < show.xyDistance)
            ) {
              show = { type: 'add', midpoint: midpoint };
            }

            if (show) {
              if (show.type === 'delete') {
                setVertexToDelete(show.vertex);
                setMidpointToCreateVertex(null);
              } else if (show.type === 'add') {
                setVertexToDelete(null);
                setMidpointToCreateVertex(show.midpoint);
              }
            } else {
              setVertexToDelete(null);
              setMidpointToCreateVertex(null);
            }
          }
        }

        updateHoverState(info);
      }}
    >
      <div className='map-type-container'>
        <MapTypeButton
          caption='Map'
          users={groupedMapType.roadmap}
          isSelected={mapType === 'roadmap'}
          onClick={() => {
            setMapType('roadmap');
            if (clientId.current) {
              sendMessage(
                BSON.serialize({
                  type: 'update_setting',
                  change: {
                    key: 'mapType',
                    value: 'roadmap',
                  },
                })
              );
            }
          }}
        />
        <MapTypeButton
          caption='Satellite'
          isSelected={mapType === 'satellite'}
          users={groupedMapType.satellite}
          onClick={() => {
            // TODO: make own method, remove code duplication
            setMapType('satellite');
            if (clientId.current) {
              sendMessage(
                BSON.serialize({
                  type: 'update_setting',
                  change: {
                    key: 'mapType',
                    value: 'satellite',
                  },
                })
              );
            }
          }}
        />
        {/* TODO: replace High-res button w/ existing EnableMetromap button when new map is implemented */}
        <MapTypeButton caption='High-res' />
      </div>

      <visGm.Map id={MAP_ID} mapTypeId={mapType} {...GM_MAP_OPTIONS} />

      <div id='lhs-map-tools'>
        <DrawingControls
          mode={mode}
          activeShape={activeShape}
          handleChangeMode={handleChangeMode}
        />
        <ProductControls
          handleClick={handleChangeDataType}
          setHoveredDataType={setHoveredDataType}
          isDisabled={(productType) => {
            if (activeShapeIds.size === 1) {
              const id = [...activeShapeIds.values()][0];
              const shape = shapesData.get(id);
              // if currently active shape is a derived data type
              //  only types that derive from the same base type are enabled
              if (isDerivedDataType(productType)) {
                const baseType = getBaseDataType(productType);
                if (shape && shape.baseShapeId) {
                  const baseShape = shapesData.get(shape.baseShapeId);
                  if (baseShape) {
                    return baseShape.dataType !== baseType;
                  }
                }
              }
              const activeDataType = shape?.dataType;
              if (activeDataType) {
                return activeDataType === productType;
              }
            }
            return false;
          }}
        />
      </div>

      <div className='debug-metrics'>
        <DebugMetrics metrics={metrics} />
      </div>
    </DeckGL>
  );
};

const Provider = () => {
  return (
    <visGm.APIProvider apiKey={process.env.GCP_MAPS_API_KEY!}>
      <Consumer />
    </visGm.APIProvider>
  );
};

export default Provider;
