import proj4 from 'proj4';
import { Spline } from './spline';
import { WorldState } from './worldState';
import { Position } from 'geojson';

type AnimationState = 'stopped' | 'idle' | 'animating';
type Animation<T> = {
  from: number[];
  to: number[];
  start: number;
  duration: number;
  extra: T;
};
export type PositionCallback<T> = (point: number[], extra: T) => void;

abstract class Interpolator<T> {
  cb: PositionCallback<T>;
  constructor(cb: PositionCallback<T>) {
    this.cb = cb;
  }
  abstract addPoint(point: number[], extra: T): void;
}

class PassThroughInterpolator<T> extends Interpolator<T> {
  addPoint(point: number[], extra: T) {
    this.cb(point, extra);
  }
}

class PointInterpolator<T> extends Interpolator<T> {
  state: AnimationState = 'idle';
  queue: Animation<T>[] = [];
  timestamp = performance.now();
  lastRequestId = 0;
  timeoutId?: NodeJS.Timeout;
  prevPoint?: number[];
  spline = new Spline();

  constructor(cb: PositionCallback<T>) {
    super(cb);
  }

  addPoint(point: number[], extra: T) {
    clearTimeout(this.timeoutId);
    const now = performance.now();
    const duration = Math.min(
      now - this.timestamp,
      PointInterpolator.MAX_INTERVAL
    );
    if (!this.prevPoint) {
      this.spline.clear();
      this.prevPoint = point;
      this.spline.addPoint(point);
      this.cb(point, extra);
      this.state = 'stopped';
      return;
    }
    if (this.state === 'stopped') {
      this.spline.clear();
      this.spline.addPoint(this.prevPoint);
      this.spline.addPoint(this.prevPoint);
      this.spline.addPoint(point);
      this.state = 'idle';
    } else {
      this.spline.addPoint(point);
    }
    const animation: Animation<T> = {
      start: this.spline.points.length - 3,
      from: this.prevPoint,
      to: point,
      duration,
      extra,
    };
    this.prevPoint = point;
    this.timestamp = now;
    switch (this.state) {
      case 'idle': {
        this.state = 'animating';
        this.animateNext(animation);
        break;
      }
      case 'animating': {
        this.queue.push(animation);
        break;
      }
    }
  }

  animateNext = (animation: Animation<T>) => {
    const start = performance.now();
    const loop = () => {
      const t = (performance.now() - start) / animation.duration;
      if (t <= 1 && this.spline.points.length > 0) {
        try {
          this.cb(
            this.spline.getSplinePoint(t + animation.start),
            animation.extra
          );
        } catch (e) {
          console.warn(e);
        }
        this.lastRequestId = requestAnimationFrame(loop);
        return;
      }
      const next = this.queue.shift();
      if (next) {
        this.state = 'animating';
        this.animateNext(next);
      } else {
        this.state = 'idle';
        this.timeoutId = setTimeout(() => {
          this.state = 'stopped';
        }, PointInterpolator.MAX_INTERVAL);
      }
    };
    loop();
  };

  static MAX_INTERVAL = 300;

  dispose = () => {
    clearTimeout(this.timeoutId);
  };
}

const PROJECTION = proj4('WGS84', 'EPSG:3857');
// const ORIGIN = PROJECTION.forward(INITIAL_MAP_OPTIONS.CENTER);

// we don't need to interpolate the current user's position

export class WorldStateInterpolator {
  private interpolators = new Map<
    string,
    Interpolator<{ worldState: WorldState }>
  >();
  interpolatedPositions = new Map<string, Position>();
  private interpolatedWorldState: WorldState|null = null;
  private prevInterpolatedWorldState: WorldState|null = null;
  private i = 0;
  private iPrev = this.i;

  private _interpolate(coord: number[], {worldState}: {worldState: WorldState}, clientId: string) {
    const position = PROJECTION.inverse(coord);

    this.interpolatedPositions.set(clientId, position);

    this.interpolatedWorldState = worldState.interpolate(
      this.interpolatedPositions
    );

    this.i++;
  }

  private createPointInterpolator(clientId: string) {
    return new PointInterpolator<{ worldState: WorldState }>(
      (coord, { worldState }) => {
        this._interpolate(coord, {worldState}, clientId);
      }
    );
  }

  private createPassThroughInterpolator(clientId: string) {
    return new PassThroughInterpolator<{worldState: WorldState}>(
      (coord, {worldState}) => {
        this._interpolate(coord, {worldState}, clientId);
      }
    )
  }

  addState(worldState: WorldState, currentClientId: string) {
    for (const [clientId, position] of worldState.getCursors()) {
      if (!this.interpolators.has(clientId)) {
        // const interpolator = currentClientId === clientId ? this.createPassThroughInterpolator(clientId) : this.createPointInterpolator(clientId);
        const interpolator = this.createPointInterpolator(clientId);
        this.interpolators.set(clientId, interpolator);
      }
      const coord = PROJECTION.forward(position);
      this.interpolators.get(clientId)?.addPoint(coord, { worldState });
    }
    for (const [clientId] of this.interpolatedPositions) {
      if (!worldState.getCursors().has(clientId)) {
        this.interpolatedPositions.delete(clientId);
      }
    }
  }

  getPrevInterpolatedWorldState() {
    return this.prevInterpolatedWorldState;
  }

  getInterpolatedWorldState() {
    this.iPrev = this.i;
    this.prevInterpolatedWorldState = this.interpolatedWorldState;
    return this.interpolatedWorldState;
  }

  hasInterpolatedWorldStateChanged() {
    return this.iPrev !== this.i;
  }

  reset() {
    this.interpolators = new Map();
    this.interpolatedPositions = new Map();
    this.interpolatedWorldState = null;
  }
}
