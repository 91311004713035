import React, { useState, useRef, useCallback } from 'react';
import fp from 'lodash/fp';
import cn from 'classnames';

import useEventListener from '../hooks/useEventListener';
import {
  ADD_ONS,
  PRODUCT_DELIVERY_METHOD,
  PRODUCT_TYPE,
} from '../constants/product';
import OutlinedText from '../components/text/OutlinedText';
import text from '../text';
import Icon from '../components/icon/Icon';

const productsByDeliveryMethod = {
  draft: [
    {
      productType: PRODUCT_TYPE.UNKNOWN,
    },
  ],
  database: [
    {
      productType: PRODUCT_TYPE.AERIAL,
    },
    {
      productType: PRODUCT_TYPE.STREETSCAPE,
    },
  ],
  capture: [
    {
      productType: PRODUCT_TYPE.DRONE,
    },
    {
      productType: PRODUCT_TYPE.EXTERIOR,
    },
    {
      productType: PRODUCT_TYPE.INTERIOR,
    },
  ],
  addOns: [
    {
      productType: PRODUCT_TYPE.BIM_EXTERIOR,
    },
    {
      productType: PRODUCT_TYPE.BIM_INTERIOR,
    },
    {
      productType: PRODUCT_TYPE.TWOD_PLAN_EXTERIOR,
    },
    {
      productType: PRODUCT_TYPE.TWOD_PLAN_INTERIOR,
    },
  ],
};

const ProductControls = ({
  handleClick,
  isDisabled,
  setHoveredDataType,
}: {
  handleClick: (productType: string) => void;
  isDisabled: (productType: string) => boolean;
  setHoveredDataType: (dataType: string | null) => void;
}) => {
  const [isScrollable, setIsScrollable] = useState(false);
  const productControlsRef = useRef<HTMLDivElement>(null);

  const isElementHeightScrollable = (element: HTMLDivElement) => {
    const { clientHeight, scrollHeight } = element;
    return setIsScrollable(scrollHeight > clientHeight);
  };

  const setScrollableOnResize = useCallback(() => {
    if (!productControlsRef.current) return;
    isElementHeightScrollable(productControlsRef.current);
  }, [productControlsRef]);

  useEventListener('resize', setScrollableOnResize);

  const handleHover = useCallback(
    (dataType: string) => () => {
      setHoveredDataType(dataType);
    },
    [setHoveredDataType]
  );

  const handleUnhover = useCallback(() => {
    setHoveredDataType(null);
  }, [setHoveredDataType]);

  return (
    <div
      className='ProductControlsMp'
      ref={(element) => {
        // necessary to add callback to set ref on load of ProductControl component
        // especially on smaller screen
        if (!element) return;
        // @ts-expect-error cannot assign to current
        productControlsRef.current = element;
        isElementHeightScrollable(element);
      }}
    >
      {map(productsByDeliveryMethod, (products, deliveryMethod) => {
        return (
          <React.Fragment key={deliveryMethod}>
            {/* @ts-expect-error props */}
            <OutlinedText
              label={text(PRODUCT_DELIVERY_METHOD[deliveryMethod])}
              svgProps={{
                width: '60px', // best fit width for labels draft, database, ...
              }}
            />
            <div className='product-control-button-group'>
              {products.map(({ productType }) => {
                const isAddOn = getIsAddOnType(productType);
                const withExplanation =
                  productType === PRODUCT_TYPE.UNKNOWN || isAddOn;
                return (
                  <ProductControlsButton
                    key={productType}
                    productType={productType}
                    variant={
                      productType === PRODUCT_TYPE.UNKNOWN
                        ? 'tertiary'
                        : 'default'
                    }
                    withExplanation={withExplanation}
                    deliveryMethod={deliveryMethod}
                    disabled={isDisabled(productType)}
                    handleClick={handleClick}
                    handleHover={handleHover(productType)}
                    handleUnhover={handleUnhover}
                  />
                );
              })}
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
};

const ProductControlsButton = ({
  productType,
  variant,
  withExplanation,
  deliveryMethod,
  handleClick,
  handleHover,
  handleUnhover,
  disabled,
}: {
  productType: string;
  variant: 'default' | 'secondary' | 'tertiary';
  withExplanation: boolean;
  deliveryMethod: keyof typeof PRODUCT_DELIVERY_METHOD;
  handleClick: (productType: string) => void; // TODO: I don't like this prop drilling, let's find another state management solution
  handleHover: () => void;
  handleUnhover: () => void;
  disabled: boolean;
}) => {
  return (
    <button
      type='button'
      className={cn(productType, {
        ProductBadge: variant !== 'secondary',
        'btn-icon': variant === 'secondary',
        tertiary: variant === 'tertiary',
        deactivated: disabled,
      })}
      onClick={() => handleClick(productType)}
      onMouseEnter={handleHover}
      onMouseLeave={handleUnhover}
      disabled={disabled}
    >
      <Icon icon={productType} />
    </button>
  );
};

const map = <K extends string, V, T>(
  obj: Record<K, V>,
  // eslint-disable-next-line no-unused-vars
  fn: (v: V, k: K) => T
) => {
  const entries = Object.entries(obj) as [K, V][];
  return entries.map(([k, v]) => fn(v, k));
};

const getIsAddOnType = (type: string) =>
  fp.values(ADD_ONS).some((a) => a.PRODUCT_NAME === type);

export default ProductControls;
