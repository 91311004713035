import { Feature, Polygon, MultiPolygon } from 'geojson';
import * as turf from '@turf/turf';
import polylabel from 'polylabel';
import proj4 from 'proj4';
import invariant from 'tiny-invariant';

import { LayerLabels } from '../coverage2';

const MIN_AREA_SQM = 100;

export type UpdateLabelsWorkerRequest = {
  shapes: Feature<Polygon | MultiPolygon>[];
  coverageLayers: LayerLabels[];
};
export type UpdateLabelsWorkerResponse = LayerLabels[];

export const updateLabelPositions = (
  shapes: Feature<Geometry>[],
  coverageLayers: LayerLabels[]
) => {
  // a lot cheaper to compute the union as just the multipolygon
  const testPoly = turf.multiPolygon(
    shapes.flatMap((shape) => {
      const geom = shape.geometry;
      if (geom.type === 'MultiPolygon') {
        return geom.coordinates;
      }
      return [geom.coordinates];
    })
  );
  // if (!testPoly) return;

  let anyChanges = false;
  const newLayers = coverageLayers.map((layer) => {
    const points = turf.points(layer.label_positions.coordinates);
    const pointsWithin = points.features.map((point, i) => {
      if (!layer.label_validities[i]) {
        layer.label_positions_original.coordinates[i] = computeVisualCenter(
          turf.polygon(layer.parts.coordinates[i])
        ).geometry.coordinates;
        layer.label_validities[i] = true;
        point = turf.point(layer.label_positions_original.coordinates[i]);
      }
      const originalPoint = turf.point(
        layer.label_positions_original.coordinates[i]
      );
      if (turf.booleanPointInPolygon(originalPoint, testPoly)) {
        return originalPoint;
      }
      if (turf.booleanPointInPolygon(point, testPoly)) {
        return point;
      }
      const intersect = turf.intersect(
        turf.featureCollection<Polygon | MultiPolygon>([
          turf.polygon(layer.parts.coordinates[i]),
          testPoly,
        ])
      );
      if (!intersect) {
        return originalPoint;
      }
      if (turf.area(intersect) < MIN_AREA_SQM) return point;
      const centroid = computeVisualCenter(intersect);
      if (!centroid) {
        return originalPoint;
      }
      anyChanges = true;
      return centroid;
    });
    return {
      ...layer,
      label_positions: turf.multiPoint(
        pointsWithin.map((p) => p.geometry.coordinates)
      ).geometry,
    };
  });

  return { newLayers, anyChanges };
};

// self.onmessage = ({
//   data: { shapes, coverageLayers },
// }: MessageEvent<UpdateLabelsWorkerRequest>) => {
//   const { newLayers, anyChanges } = updateLabelPositions(
//     shapes,
//     coverageLayers
//   );
//   if (anyChanges) {
//     self.postMessage(newLayers satisfies UpdateLabelsWorkerResponse);
//   }
// };

export {};

const PROJECTION = proj4('WGS84', 'EPSG:3857');

type Geometry = Polygon | MultiPolygon;

function computeVisualCenter(feat: Geometry | Feature<Geometry>) {
  const geom = feat.type === 'Feature' ? feat.geometry : feat;
  let poly: Feature<Polygon>;
  if (geom.type === 'MultiPolygon') {
    const largest = geom.coordinates.reduce<{
      area: number;
      poly: Feature<Polygon>;
    } | null>((max, current) => {
      const poly = turf.polygon(current);
      const area = turf.area(poly);
      if (!max || area > max.area) {
        return { area, poly };
      }
      return max;
    }, null);
    invariant(largest);
    poly = largest.poly;
  } else {
    poly = turf.feature(geom);
  }
  const points = poly.geometry.coordinates.map((coords) =>
    coords.map(PROJECTION.forward)
  );
  const p = polylabel(points);
  const [cx, cy] = [p[0], p[1]];
  const [cxt, cyt] = PROJECTION.inverse([cx, cy]);
  return turf.point([cxt, cyt]);
}
