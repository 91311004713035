import {
  Color,
  CompositeLayer,
  GetPickingInfoParams,
  LayersList,
  PickingInfo,
} from '@deck.gl/core';
import { GeoJsonLayer, GeoJsonLayerProps } from '@deck.gl/layers';
import invariant from 'tiny-invariant';
import * as turf from '@turf/turf';
import {
  BBox,
  Feature,
  GeoJsonProperties,
  GeoJsonTypes,
  Geometry,
  LineString,
  MultiPolygon,
  Point,
  Polygon,
  Position,
} from 'geojson';

import { Tentative, WorldStateT } from './worldState';
import {
  MidpointToCreateVertex,
  PolygonMidpointToCreateVertex,
} from './RealtimeMap';
import {
  BooleanVertexFeature,
  MapShape,
  PathVertexFeature,
  PolygonVertexFeature,
  RectangleVertexFeature,
  TentativeBoolean,
  TentativeBooleanVertexFeature,
  TentativeFinalVertexFeature,
  TentativePathVertexFeature,
  TentativePolygonVertexFeature,
  TentativeRectangleVertexFeature,
} from './lib/shape';
import { arrayLib } from './lib/array';
import { object } from 'zod';
import { MaskExtension } from '@deck.gl/extensions';
import { geometryLib } from './lib/geometry';
import { element } from 'prop-types';

type PickingInfoDataT =
  | TentativePolygonVertexFeature
  | TentativeRectangleVertexFeature
  | TentativePathVertexFeature
  | PolygonVertexFeature
  | BooleanVertexFeature<'rectangle'>
  | BooleanVertexFeature<'polygon'>
  | RectangleVertexFeature
  | Feature<Polygon, null>
  | Feature<MultiPolygon, null>
  | Feature<LineString, null>;
type PickingExtraInfoT = {
  handle?:
    | TentativePolygonVertexFeature['properties']
    | TentativeRectangleVertexFeature['properties']
    | TentativePathVertexFeature['properties']
    | TentativeBooleanVertexFeature['properties']
    | PolygonVertexFeature['properties']
    | RectangleVertexFeature['properties']
    | PathVertexFeature['properties']
    | BooleanVertexFeature<'rectangle'>['properties']
    | BooleanVertexFeature<'polygon'>['properties'];
  shape?: { type: 'polygon' | 'rectangle' | 'path'; id: string };
};
type PickingInfoParamsT = GetPickingInfoParams<
  PickingInfoDataT,
  PickingExtraInfoT
>;

export type ShapeLayerPickingInfoT = PickingInfo<
  PickingInfoDataT,
  PickingExtraInfoT
>;

type TentativeSequence = (
  | TentativePolygonVertexFeature
  | TentativeFinalVertexFeature
  | TentativeRectangleVertexFeature
  | TentativePathVertexFeature
)[];

type Props = {
  data: {
    shapes: WorldStateT['shapes'];
    tentativeShapes: WorldStateT['tentativeShapes'];
    activeShapeIds: Set<string>;
  };
};

const tentativeProperties = { shapeVariant: 'tentative' } as const;

const tentativePolygonFeatureFromSequence = (seq: TentativeSequence) => {
  invariant(seq.length >= 2, 'sequence needs >= 2 elements');
  const coords = seq.map((feature) => feature.geometry.coordinates);
  if (seq.length === 2) {
    return turf.lineString(coords, tentativeProperties);
  }
  return turf.polygon([[...coords, coords[0]]], tentativeProperties);
};

const tentativeBooleanFeatureFromSequence = (seq: TentativeSequence) => {
  if (seq.length !== 2) return null;
  const coords = seq.map((feature) => feature.geometry.coordinates);
  return turf.lineString(coords, tentativeProperties);
};

const rectangleBoundsFromSequence = (seq: TentativeSequence): BBox => {
  invariant(seq.length === 2, 'rectangle needs 2 vertices');
  const [vA, vB] = seq;
  const [x0, y0] = vA.geometry.coordinates;
  const [x1, y1] = vB.geometry.coordinates;
  return [
    Math.min(x0, x1),
    Math.min(y0, y1),
    Math.max(x0, x1),
    Math.max(y0, y1),
  ];
};

const tentativeRectangleFeatureFromSequence = (seq: TentativeSequence) => {
  const bounds = rectangleBoundsFromSequence(seq);
  return turf.bboxPolygon(bounds, { properties: tentativeProperties });
};

const pathFeatureFromSequence = <T extends object>(
  seq: Feature<Point>[],
  properties: T
) => {
  invariant(seq.length >= 2);
  const PATH_WIDTH_METERS = 20;
  const MIN_DISTANCE_TO_SHOW_FINAL_SECTOR_METERS = 1;

  const elements: Feature<Polygon | MultiPolygon, T>[] = [];
  for (let i = 0; i < seq.length - 1; i++) {
    const p1 = turf.point(turf.getCoord(seq[i]));
    const p2 = turf.point(turf.getCoord(seq[i + 1]));
    if (i == seq.length - 2) {
      const dst = turf.distance(p1, p2, { units: 'meters' });
      if (dst < MIN_DISTANCE_TO_SHOW_FINAL_SECTOR_METERS) {
        continue;
      }
    }
    const bearing = turf.bearing(p1, p2);
    const p0a = turf.destination(p1, PATH_WIDTH_METERS, bearing + 90, {
      units: 'meters',
    });
    const p0b = turf.destination(p1, PATH_WIDTH_METERS, bearing - 90, {
      units: 'meters',
    });
    const p1a = turf.destination(p2, PATH_WIDTH_METERS, bearing + 90, {
      units: 'meters',
    });
    const p1b = turf.destination(p2, PATH_WIDTH_METERS, bearing - 90, {
      units: 'meters',
    });
    const rect = turf.polygon(
      [
        [
          turf.getCoord(p0a),
          turf.getCoord(p0b),
          turf.getCoord(p1b),
          turf.getCoord(p1a),
          turf.getCoord(p0a),
        ],
      ],
      properties
    );
    // buffer slightly to ensure overlap happens
    let rectBuffered = turf.buffer(rect, 0.001, { units: 'meters' }) as Feature<
      Polygon | MultiPolygon,
      T
    >;
    invariant(rectBuffered);
    rectBuffered = { ...rectBuffered, properties };

    elements.push(rectBuffered);
    if (i > 0) {
      const p0 = turf.point(turf.getCoord(seq[i - 1]));
      const priorBearing = turf.bearing(p0, p1);
      // get the signed-angle difference in [-180,180] https://stackoverflow.com/a/61901403
      const diff = ((bearing - priorBearing + 360 + 180) % 360) - 180;
      const _to360 = (angle: number) => (angle + 360) % 360;
      const _to180 = (angle: number) =>
        angle > 180 ? angle - 360 : angle < -180 ? angle + 360 : angle;
      const angle0 = _to360(priorBearing);
      const angle1 = _to360(bearing);
      const a0 = _to180(_to360(diff >= 0 ? angle0 - 90 : angle1 + 90));
      const a1 = _to180(_to360(diff >= 0 ? angle1 - 90 : angle0 + 90));
      const controlPointSector = turf.sector(p1, PATH_WIDTH_METERS, a0, a1, {
        units: 'meters',
      });
      let controlPointSectorBuffered = turf.buffer(controlPointSector, 0.001, {
        units: 'meters',
      }) as Feature<Polygon, T>;
      invariant(controlPointSectorBuffered);
      controlPointSectorBuffered = {
        ...controlPointSectorBuffered,
        properties,
      };
      elements.push(controlPointSectorBuffered);
    }
  }
  if (elements.length === 0) return null;
  let surrounding: Feature<Polygon | MultiPolygon, T> | null;
  try {
    surrounding =
      elements.length === 1
        ? elements[0]
        : geometryLib.union(elements, properties);
    invariant(surrounding);
    surrounding.properties = properties;
  } catch (e) {
    console.error(e);
    for (const element of elements) {
      console.log(element, turf.booleanValid(element));
    }
    console.error(seq);
    throw e;
  }
  return surrounding;
};

export const toShapeFeatures = (
  node: Map<string, MapShape>,
  booleanFeatures: Map<string, Position[]>
) => {
  // console.time('toShapeFeatures');
  const features = [...node]
    .map(([shapeId, shape]) => {
      const shapeProperties = {
        mapShapeType: shape.type,
        shapeId,
        dataType: shape.dataType,
        baseShapeId: shape.baseShapeId,
      };
      if (shape.type === 'polygon') {
        if (shape.exterior.length < 3) return null;
        const exterior = geometryLib.getCoords(shape.exterior);
        const holes = [...shape.holes.values()].map(geometryLib.getCoords);
        if (booleanFeatures.has(shapeId)) {
          const tentativeBoolean = booleanFeatures.get(shapeId)!;
          if (tentativeBoolean.length >= 3) {
            holes.push(tentativeBoolean);
          }
        }
        let polygon: Feature<
          Polygon | MultiPolygon,
          typeof shapeProperties
        > | null = turf.polygon([[...exterior, exterior[0]]], shapeProperties);
        for (const hole of holes) {
          if (polygon && hole?.length > 0) {
            polygon = geometryLib.difference(
              polygon,
              turf.polygon([[...hole, hole[0]]])
            );
          }
        }
        return polygon;
      } else if (shape.type === 'rectangle') {
        const vs = shape.exterior;
        let polygon: Feature<
          Polygon | MultiPolygon,
          typeof shapeProperties
        > | null = turf.polygon(
          [geometryLib.getCoords([...vs, vs[0]])],
          shapeProperties
        );
        const holes = [...shape.holes.values()].map(geometryLib.getCoords);
        if (booleanFeatures.has(shapeId)) {
          const tentativeBoolean = booleanFeatures.get(shapeId)!;
          if (tentativeBoolean.length >= 3) {
            holes.push(tentativeBoolean);
          }
        }
        for (const hole of holes) {
          if (polygon && hole?.length > 0) {
            try {
              polygon = geometryLib.difference(
                polygon,
                turf.polygon([[...hole, hole[0]]])
              );
            } catch (e) {
              console.error(hole);
              throw e;
            }
          }
        }
        return polygon;
      } else if (shape.type === 'path') {
        if (shape.vertices.length < 2) return null;
        return pathFeatureFromSequence(shape.vertices, shapeProperties);
      }
      throw new Error('unknown shape');
    })
    .filter(arrayLib.filterNonNull);
  // console.timeEnd('toShapeFeatures');
  return features;
};

const getTentativeFeatures = (data: Props['data']) => {
  // console.time('getTentativeFeatures');
  const tentativeHandles = [...data.tentativeShapes.values()]
    .map(({ shape }) => {
      if (!shape) return null;
      if (shape.type === 'polygon') {
        return Object.values(shape.vertices);
      } else if (shape.type === 'rectangle') {
        return [shape.initial, ...Object.values(shape.derived)];
      } else if (shape.type === 'path') {
        return shape.vertices;
      } else if (shape.type === 'boolean') {
        return Object.values(shape.vertices);
      }
      throw new Error('unknown shape');
    })
    .flat()
    .filter(arrayLib.filterNonNullAndNonUndefined);
  const tentativeFeatures = [...data.tentativeShapes.values()]
    .map(({ shape }) => {
      if (!shape) return null;
      if (shape.type === 'polygon') {
        const v = Object.values(shape.vertices);
        return {
          type: shape.type,
          sequence: shape.final ? [...v, shape.final] : v,
        };
      } else if (shape.type === 'rectangle') {
        return {
          type: shape.type,
          sequence: shape.final
            ? [shape.initial, shape.final]
            : [shape.initial],
        };
      } else if (shape.type === 'path') {
        return {
          type: shape.type,
          sequence: shape.final
            ? [...shape.vertices, shape.final]
            : shape.vertices,
        };
      } else if (shape.type === 'boolean') {
        const sequence = shape.final
          ? [...Object.values(shape.vertices), shape.final]
          : Object.values(shape.vertices);
        return { type: shape.type, sequence: sequence };
      }
      throw new Error('unknown shape');
    })
    .filter(arrayLib.filterNonNull)
    .filter(({ sequence }) => sequence.length >= 2)
    .map(({ type, sequence }) => {
      if (type === 'polygon') {
        return tentativePolygonFeatureFromSequence(sequence);
      } else if (type === 'rectangle') {
        return tentativeRectangleFeatureFromSequence(sequence);
      } else if (type === 'path') {
        return pathFeatureFromSequence(sequence, tentativeProperties);
      } else if (type === 'boolean') {
        return tentativeBooleanFeatureFromSequence(sequence);
      }
      throw new Error(`unknown type ${type}`);
    })
    .filter(arrayLib.filterNonNull);
  // console.timeEnd('getTentativeFeatures');
  return { tentativeHandles, tentativeFeatures };
};

const getShapeFeatures = (data: Props['data']) => {
  // console.time('getShapeFeatures');
  const tentativeBooleanFeatures = new Map(
    [...data.tentativeShapes]
      .filter(
        (
          entry
        ): entry is [
          string,
          { sequenceIds: string[]; shape: TentativeBoolean },
        ] => {
          return entry[1].shape?.type === 'boolean';
        }
      )
      .map(([, tentative]) => {
        const v = Object.values(tentative.shape.vertices).map(turf.getCoord);
        if (tentative.shape.final) {
          v.push(turf.getCoord(tentative.shape.final));
        }
        return [tentative.shape?.parentShapeId, v];
      })
  );
  const shapeFeatures = toShapeFeatures(data.shapes, tentativeBooleanFeatures);
  const shapeHandles = [...data.shapes]
    .filter(([shapeId]) => data.activeShapeIds.has(shapeId))
    .map(([, shape]) => {
      if (shape.type === 'polygon') {
        return [shape.exterior, ...shape.holes.values()];
      } else if (shape.type === 'rectangle') {
        return [shape.exterior, ...shape.holes.values()];
      } else if (shape.type === 'path') {
        return [shape.vertices];
      }
      throw new Error('unknown shape');
    })
    .flat(2);
  const holeLines = [...data.shapes]
    .filter(([shapeId]) => data.activeShapeIds.has(shapeId))
    .map(([, shape]) => {
      if (shape.type === 'polygon' || shape.type === 'rectangle') {
        const holes = [...shape.holes.values()].map(geometryLib.getCoords);
        return holes
          .map((h) => (h.length >= 3 ? turf.lineString([...h, h[0]]) : null))
          .filter(arrayLib.filterNonNull);
      }
      return [];
    })
    .flat(2);
  // console.timeEnd('getShapeFeatures');
  return { shapeHandles, shapeFeatures, holeLines };
};

export default class ShapeLayer extends CompositeLayer<Props> {
  getPickingInfo({ info }: PickingInfoParamsT): ShapeLayerPickingInfoT {
    const data = info.object;
    if (data?.type === 'Feature') {
      if (
        data.geometry.type === 'Polygon' ||
        data.geometry.type === 'MultiPolygon'
      ) {
        if ((data.properties as any).mapShapeType) {
          info.shape = {
            type: (data.properties as any).mapShapeType,
            id: (data.properties as any).shapeId,
          };
        }
      } else if (data.geometry.type === 'LineString') {
        //
      }
    }
    return info;
  }

  renderLayers() {
    // console.time('ShapeLayer renderLayers');
    const { tentativeFeatures } = getTentativeFeatures(this.props.data);
    const { shapeFeatures, holeLines } = getShapeFeatures(this.props.data);
    const [activeShapeFeatures, inactiveShapeFeatures] = partitionBy(
      shapeFeatures,
      (f) => this.props.data.activeShapeIds.has(f.properties.shapeId)
    );
    const layers = [
      new GeoJsonLayer({
        id: 'tentative_shapes',
        data: turf.featureCollection<LineString | Polygon | MultiPolygon>(
          tentativeFeatures
        ),
        pickable: true,
        stroked: true,
        getFillColor: TENTATIVE_FILL_COLOR,
        getLineColor: TENTATIVE_LINE_COLOR,
        ...commonShapeProps,
      }),

      // display inactive shapes atop active shapes
      // TODO: when hide/showing shapes use the DataFilterExtension
      createShapeLayer(activeShapeFeatures, 'active_shapes'),
      createShapeLayer(holeLines, 'hole_lines'),
      createShapeLayer(inactiveShapeFeatures, 'inactive_shapes'),
    ];
    // console.timeEnd('ShapeLayer renderLayers');
    return layers;
  }
}

export class ShapeHandleLayer extends CompositeLayer<Props> {
  getPickingInfo({ info }: PickingInfoParamsT): ShapeLayerPickingInfoT {
    const data = info.object;
    if (data?.type === 'Feature') {
      if (data.properties?.type === 'tentative') {
        info.handle = data.properties;
      } else if (data.properties?.type === 'shape') {
        info.handle = data.properties;
      }
    }
    return info;
  }

  renderLayers() {
    const { tentativeHandles } = getTentativeFeatures(this.props.data);
    const { shapeHandles } = getShapeFeatures(this.props.data);
    return [
      new GeoJsonLayer({
        id: 'shape_handles',
        data: shapeHandles,
        pickable: true,
        stroked: true,
        getFillColor: SHAPE_HANDLE_FILL_COLOR,
        getLineColor: SHAPE_HANDLE_LINE_COLOR,
        ...commonHandleProps,
      }),
      new GeoJsonLayer({
        id: 'tentative_handles',
        data: tentativeHandles,
        pickable: true,
        stroked: true,
        getFillColor: TENTATIVE_HANDLE_FILL_COLOR,
        getLineColor: TENTATIVE_HANDLE_LINE_COLOR,
        ...commonHandleProps,
      }),
    ];
  }
}

const TENTATIVE_FILL_COLOR: Color = [160, 160, 180, 150];
const TENTATIVE_LINE_COLOR: Color = [160, 160, 180, 255];
const TENTATIVE_HANDLE_FILL_COLOR: Color = [160, 160, 180, 255];
const TENTATIVE_HANDLE_LINE_COLOR: Color = [160, 160, 180, 255];

const SHAPE_FILL_COLOR: Color = [160, 160, 180, 80];
const SHAPE_LINE_COLOR: Color = [0, 0, 0, 100];
const SHAPE_HANDLE_FILL_COLOR: Color = [255, 255, 255, 255];
const SHAPE_HANDLE_LINE_COLOR: Color = [0, 0, 0, 100];

const commonHandleProps: Partial<GeoJsonLayerProps> = {
  pointRadiusUnits: 'meters',
  getPointRadius: 2,
  getLineWidth: 1,
  pointRadiusMinPixels: 4,
  lineWidthMinPixels: 2,
};

const commonShapeProps: Partial<GeoJsonLayerProps> = {
  getLineWidth: 1,
  lineWidthMinPixels: 2,
};

const createShapeLayer = <
  G extends Polygon | MultiPolygon | LineString,
  P extends GeoJsonProperties,
>(
  features: Feature<G, P>[],
  id: string
) => {
  return new GeoJsonLayer({
    id,
    data: features,
    pickable: true,
    stroked: true,
    getFillColor: SHAPE_FILL_COLOR,
    getLineColor: SHAPE_LINE_COLOR,
    ...commonShapeProps,
  });
};

const partitionBy = <T>(arr: T[], fn: (t: T) => boolean) =>
  arr.reduce<[T[], T[]]>(
    (acc, val) => {
      if (fn(val)) {
        acc[0].push(val);
      } else {
        acc[1].push(val);
      }
      return acc;
    },
    [[], []]
  );
