export const DATA_TYPES = [
  'streetscape',
  'aerial',
  'drone',
  'exterior',
  'interior',
] as const;
type DataType = (typeof DATA_TYPES)[number];
export const DERIVED_DATA_TYPES = {
  exterior: ['bim_exterior', 'twod_plan_exterior'],
  interior: ['bim_interior', 'twod_plan_interior'],
} as const;
type DerivedDataType =
  (typeof DERIVED_DATA_TYPES)[keyof typeof DERIVED_DATA_TYPES][number];
const DERIVED_TO_BASE_DATA_TYPE = Object.fromEntries(
  Object.entries(DERIVED_DATA_TYPES).flatMap(([base, derived]) =>
    derived.map((d) => [d, base as keyof typeof DERIVED_DATA_TYPES])
  )
);
export const isDerivedDataType = (
  dataType: string
): dataType is DerivedDataType =>
  Object.values(DERIVED_DATA_TYPES)
    .flat()
    .some((t) => t === dataType);
export const getBaseDataType = (dataType: DerivedDataType): DataType =>
  DERIVED_TO_BASE_DATA_TYPE[dataType];
