const darkenHexColor = (hex: string, percent: number = 20) => {
  hex = hex.replace('#', '');

  // Convert the hex color to RGB
  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);

  // Decrease each color component by the given percentage
  r = Math.max(0, r - r * (percent / 100));
  g = Math.max(0, g - g * (percent / 100));
  b = Math.max(0, b - b * (percent / 100));

  // Convert back to hex and return as a string
  const darkenedColor = `#${[r, g, b]
    .map((c) => Math.round(c).toString(16).padStart(2, '0'))
    .join('')}`;

  return darkenedColor;
};

export const colorLib = {
  darkenHexColor,
};
