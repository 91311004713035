export type PositionIndices = [number, number];
type PositionIndicesString = `${number};${number}`;

import { Position } from "geojson";

export type Vertex = {
  position: Position;
  positionIndices: [number, number];
};
const toString = (vertex: Vertex) => {
  return JSON.stringify(vertex);
};
const fromString = (s: string): Vertex => {
  return JSON.parse(s);
};
const positionIndicesToString = ([
  i,
  j,
]: PositionIndices): PositionIndicesString => `${i};${j}`;
const positionIndicesFromString = (s: string): PositionIndices => {
  const p = s.split(";");
  return [parseInt(p[0], 10), parseInt(p[1], 10)];
};

export const vertexLib = {
  positionIndicesToString,
  positionIndicesFromString,
  toString,
  fromString,
};
