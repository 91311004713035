import { Polygon, Feature, MultiPolygon } from 'geojson';
import * as turf from '@turf/turf';

export type ExtentsInner = Map<
  string,
  Feature<Polygon | MultiPolygon, { dataType: string }>
>;

export class Extents {
  private _map: ExtentsInner;
  constructor(map: ExtentsInner = new Map()) {
    this._map = map;
  }
  static fromCoverage(
    coverage: Feature<Polygon | MultiPolygon, { dataType: string }>[]
  ) {
    const map: ExtentsInner = new Map();
    for (const feature of coverage) {
      const { dataType } = feature.properties;
      const existing = map.get(dataType);
      const bbox = turf.bboxPolygon(turf.bbox(feature), {
        properties: { dataType },
      });
      if (!bbox) {
        console.warn('no bbox');
        continue;
      }
      const union = existing
        ? turf.union(turf.featureCollection([existing, bbox]), {
            properties: { dataType },
          })
        : bbox;
      if (!union) continue;
      map.set(dataType, union);
    }
    return new Extents(map);
  }
  getInner() {
    return this._map;
  }
  union(other: Extents) {
    const map = new Map(this._map); // makes copy
    for (const [key, value] of other._map) {
      const existing = map.get(key);
      const union = existing
        ? turf.union(turf.featureCollection([existing, value]), {
            properties: { dataType: existing.properties.dataType },
          })
        : value;
      if (!union) continue;
      map.set(key, union);
    }
    return new Extents(map);
  }
  within(other: Extents) {
    return [...this._map].every(([key, value]) => {
      const otherValue = other._map.get(key);
      if (!otherValue) return false;
      const geom = value.geometry;
      if (geom.type === 'MultiPolygon') {
        return geom.coordinates.every((c) => {
          return turf.booleanWithin(turf.polygon(c), otherValue);
        });
      }
      return turf.booleanWithin(geom, otherValue);
    });
  }
  buffer(amount: number) {
    return new Extents(
      new Map(
        [...this._map]
          .map(([key, value]) => {
            // const buffed = turf.buffer(value, amount, {
            //   units: 'meters',
            // }) as Feature<Polygon | MultiPolygon, { dataType: string }>;
            // buffed.properties = { dataType: key };
            // return buffed ? ([key, buffed] as const) : null;
            return [key, value] as const;
          })
          .filter(filterNonNull)
      )
    );
  }
}

const filterNonNull = <T>(x: T | null): x is T => {
  return x !== null;
};

const mergePolygons = <
  A extends Polygon | MultiPolygon,
  B extends Polygon | MultiPolygon,
>(
  a: A | Feature<A>,
  b: B | Feature<B>
) => {
  const ga = a.type === 'Feature' ? a.geometry : a;
  const gb = b.type === 'Feature' ? b.geometry : b;
  const merged: MultiPolygon['coordinates'] = [];
  merged.push(
    ...(ga.type === 'MultiPolygon' ? ga.coordinates : [ga.coordinates])
  );
  merged.push(
    ...(gb.type === 'MultiPolygon' ? gb.coordinates : [gb.coordinates])
  );
  return merged;
};
