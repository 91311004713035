import { Position } from "geojson";

export type PositionIdPair = { position: Position; id: string };

export enum Corner {
  ne,
  nw,
  sw,
  se,
}

type CornerString = "ne" | "nw" | "sw" | "se";

const cornerFromEdges = (northEdge: boolean, eastEdge: boolean): Corner => {
  if (northEdge) {
    if (eastEdge) {
      return Corner.ne;
    } else {
      return Corner.nw;
    }
  } else {
    if (eastEdge) {
      return Corner.se;
    } else {
      return Corner.sw;
    }
  }
};

const getOppositeCorner = (corner: Corner): Corner => {
  switch (corner) {
    case Corner.ne:
      return Corner.sw;
    case Corner.nw:
      return Corner.se;
    case Corner.se:
      return Corner.nw;
    case Corner.sw:
      return Corner.ne;
  }
};

const getAdjacentCorners = (corner: Corner): [Corner, Corner] => {
  switch (corner) {
    case Corner.ne:
      return [Corner.nw, Corner.se];
    case Corner.nw:
      return [Corner.ne, Corner.sw];
    case Corner.se:
      return [Corner.sw, Corner.ne];
    case Corner.sw:
      return [Corner.se, Corner.nw];
  }
};

const cornerToString = (corner: Corner): CornerString => {
  switch (corner) {
    case Corner.ne:
      return "ne";
    case Corner.nw:
      return "nw";
    case Corner.se:
      return "se";
    case Corner.sw:
      return "sw";
  }
};

const cornerFromString = (corner: string): Corner => {
  switch (corner as CornerString) {
    case "ne":
      return Corner.ne;
    case "nw":
      return Corner.nw;
    case "se":
      return Corner.se;
    case "sw":
      return Corner.sw;
  }
};

export const rectangleLib = {
  cornerFromEdges,
  getOppositeCorner,
  getAdjacentCorners,
  cornerToString,
  cornerFromString,
};
