import { Position, Feature, Point } from 'geojson';

import { changeOps, Change, idLib } from '@larki/mp';
import { positionLib } from './position';
import { MapPath, MapPolygon, MapRectangle } from './shape';
import { rectangleLib } from './rectangle';

export const mapChangeOps = {
  tentative: {
    delete: (_clientId: string, t: number): Change[] => [
      changeOps.delete(['clients', _clientId, 'tentativeSequence'], t),
    ],
  },
  polygon: {
    exterior: {
      add: (
        featureId: string,
        vertexId: string,
        prevId: string,
        position: Position,
        t: number
      ): Change[] => [
        changeOps.insert(
          ['polygons', featureId, 'exterior', vertexId],
          { value: positionLib.toString(position), t },
          prevId
        ),
      ],
      update: (
        featureId: string,
        vertexId: string,
        position: Position,
        t: number
      ): Change[] => [
        changeOps.set(['polygons', featureId, 'exterior', vertexId], {
          value: positionLib.toString(position),
          t,
        }),
      ],
      delete: (featureId: string, vertexId: string, t: number): Change[] => [
        changeOps.delete(['polygons', featureId, 'exterior', vertexId], t),
      ],
    },
    all: {
      delete: (featureId: string, t: number): Change[] => [
        changeOps.delete(['polygons', featureId], t),
      ],
    },
  },
  rectangle: {
    exterior: {
      update: (
        featureId: string,
        vertexId: string,
        position: Position,
        t: number
      ): Change[] => [
        changeOps.set(
          ['rectangles', featureId, 'exterior', vertexId, 'position'],
          { value: positionLib.toString(position), t }
        ),
      ],
    },
  },
  path: {
    vertex: {
      add: (
        featureId: string,
        vertexId: string,
        prevId: string,
        position: Position,
        t: number
      ): Change[] => [
        changeOps.insert(
          ['paths', featureId, vertexId],
          { value: positionLib.toString(position), t },
          prevId
        ),
      ],
      update: (
        featureId: string,
        vertexId: string,
        position: Position,
        t: number
      ): Change[] => [
        changeOps.set(['paths', featureId, vertexId], {
          value: positionLib.toString(position),
          t,
        }),
      ],
      delete: (featureId: string, vertexId: string, t: number): Change[] => [
        changeOps.delete(['paths', featureId, vertexId], t),
      ],
    },
    all: {
      delete: (featureId: string, t: number): Change[] => [
        changeOps.delete(['paths', featureId], t),
      ],
    },
  },
  hole: {
    vertex: {
      add: (
        key: 'polygons' | 'rectangles',
        featureId: string,
        vertexId: string,
        prevId: string,
        position: Position,
        holeId: string,
        t: number
      ): Change[] => [
        changeOps.insert(
          [key, featureId, 'holes', holeId, vertexId],
          { value: positionLib.toString(position), t },
          prevId
        ),
      ],
      update: (
        key: 'polygons' | 'rectangles',
        featureId: string,
        holeId: string,
        vertexId: string,
        position: Position,
        t: number
      ): Change[] => [
        changeOps.set([key, featureId, 'holes', holeId, vertexId], {
          value: positionLib.toString(position),
          t,
        }),
      ],
      delete: (
        key: 'polygons' | 'rectangles',
        featureId: string,
        holeId: string,
        vertexId: string,
        t: number
      ): Change[] => [
        changeOps.delete([key, featureId, 'holes', holeId, vertexId], t),
      ],
    },
    all: {
      delete: (
        key: 'polygons' | 'rectangles',
        featureId: string,
        holeId: string,
        t: number
      ): Change[] => [changeOps.delete([key, featureId, 'holes', holeId], t)],
    },
  },
  dataType: {
    change: (
      key: 'polygons' | 'rectangles' | 'paths',
      dataType: string,
      featureId: string,
      t: number
    ): Change[] => [
      changeOps.set([key, featureId, 'dataType'], { value: dataType, t }),
      changeOps.delete([key, featureId, 'baseFeatureId'], t),
    ],
    changeDerived: (
      key: 'polygons' | 'rectangles' | 'paths',
      featureId: string,
      baseFeatureId: string,
      t: number
    ): Change[] => [
      changeOps.set([key, featureId, 'baseFeatureId'], {
        value: baseFeatureId,
        t,
      }),
    ],
  },
};

const copyRing = <T>(
  ring: Feature<Point, T>[],
  t: number,
  getParentPath: (properties: T) => string[]
): Change[] => {
  const newIds = ring.map(idLib.shortId);
  return [
    ...newIds.map((id, i): Change => {
      const path = [...getParentPath(ring[i].properties), id];
      return i === 0
        ? changeOps.head(path, {
            value: positionLib.toString(ring[i].geometry.coordinates),
            t,
          })
        : changeOps.insert(
            path,
            {
              value: positionLib.toString(ring[i].geometry.coordinates),
              t,
            },
            newIds[i - 1]
          );
    }),
  ];
};

export const copyPolygon = (
  original: MapPolygon,
  newFeatureId: string,
  t: number
): Change[] => {
  return [
    ...copyRing(original.exterior, t, () => [
      'polygons',
      newFeatureId,
      'exterior',
    ]),
    ...[...original.holes.values()].flatMap((hole) =>
      copyRing(hole, t, (props) => [
        'polygons',
        newFeatureId,
        'holes',
        props.holeId,
      ])
    ),
  ];
};

export const copyRectangle = (
  original: MapRectangle,
  newFeatureId: string,
  t: number
): Change[] => {
  return [
    ...original.exterior.flatMap((vertex): Change[] => {
      const newVertexId = idLib.shortId();
      return [
        changeOps.set(
          ['rectangles', newFeatureId, 'exterior', newVertexId, 'position'],
          {
            value: positionLib.toString(vertex.geometry.coordinates),
            t,
          }
        ),
        changeOps.set(
          ['rectangles', newFeatureId, 'exterior', newVertexId, 'corner'],
          {
            value: rectangleLib.cornerToString(vertex.properties.corner),
            t,
          }
        ),
      ];
    }),
    ...[...original.holes.values()].flatMap((hole) =>
      copyRing(hole, t, (props) => [
        'rectangles',
        newFeatureId,
        'holes',
        props.holeId,
      ])
    ),
  ];
};

export const copyPath = (
  original: MapPath,
  newFeatureId: string,
  t: number
): Change[] => {
  return copyRing(original.vertices, t, () => ['paths', newFeatureId]);
};
