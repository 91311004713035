/**
 * Introducing...
 *
 * COVERAGE V2
 *
 * What is coverage?
 *
 * 2D geometry representing the top-down extent of 3D data.
 * Often it is represented using a Polygon or MultiPolygon.
 *
 * What is different about V2?
 *
 * The new map renderer uses the Deck.GL backend.
 * This provides the convenient `MaskExtension` which allows us to only show only the part of shapes up to some boundary.
 * Previously, this used expensive `turf.intersect` calls on the CPU; with Deck.GL we're moving this to the GPU, which is makes it vastly more efficient.
 *
 * Still there is the problem of what coverage to load and at what frequency from the "coverage engine".
 *
 * We'll start with a naive algorithm:
 *
 * const [pendingExtents, setPendingExtents] = useState(new Extents())
 * const [coverageExtents, setCoverageExtents] = useState(new Extents())
 * useEffect(() => {
 *   const extents = Extents.fromShapes(shape)
 *   if (!extents.within(pendingExtents.union(coverageExtents))) {
 *     const queryId = new UUID();
 *     setPendingExtents(prev => prev.add(queryId, extents))
 *     fetchCoverage(extents)
 *     .then((covg) => setCoverageExtents(prev => prev.add(Extents.fromCoverage(covg))))
 *     .finally(() => setPendingExtents(prev => prev.remove(queryId)))
 *   }
 * }, [shapes])
 *
 * How to determine the position of the labels?
 *
 * An overarching goal is to limit the amount of computation on the frontend as much as possible.
 * You'll see in engine/src/coverage2.py that the centroids of each layer's "parts" are computed using the engine with PostGIS.
 * However, there are instances where the centroids are not visible, but part of the shape is.
 * In this instance, we want to reposition each label to be at the centroid of the intersection between each part and the shape union.
 * We'll need to do this whenever any of the shapes change; but we want to limit the number of intersection computations as much as possible.
 * So whenever we fetch coverage, we'll assign each "part" an ID, call it PID - create a map PID->centroid.
 * Whenever any of the shapes change, compute the shape union, for each label, check if it's within the shape union;
 *  if it is, do nothing; else, compute the intersection between the shape union and the part, set the position to the centroid of that intersection.
 */

import { Polygon, Feature, MultiPolygon, MultiPoint } from 'geojson';
import * as turf from '@turf/turf';
import { useCallback, useEffect, useMemo, useState } from 'react';
import invariant from 'tiny-invariant';
import { useThrottle } from 'react-use';

import { larkiApi } from '../utilities/api';
import { captureError } from '../utilities/error';
import {
  updateLabelPositions,
  UpdateLabelsWorkerRequest,
  UpdateLabelsWorkerResponse,
} from './workers/updateLabels.worker';
import {
  UpdateShapeExtentsWorkerRequest,
  UpdateShapeExtentsWorkerResponse,
} from './workers/updateShapeExtents.worker';
import { Extents } from './extents';

export type LayerGeometries = {
  geometry: Polygon | MultiPolygon;
  category_name: string;
  is_full: boolean;
};

export type LayerLabels = {
  label_positions: MultiPoint;
  label_positions_original: MultiPoint;
  label_priorities: number[];
  label_validities: boolean[];
  parts: MultiPolygon;
  acquired_at: string;
  category_name: string;
};

export const useCoverage = () => {
  const updateShapeExtentsWorker = useMemo(
    () =>
      new Worker(
        new URL('./workers/updateShapeExtents.worker.ts', import.meta.url)
      ),
    []
  );
  const [shapeExtents, setShapeExtents] = useState(new Extents());

  const updateShapeExtents = useCallback(
    (shapes: Feature<Polygon | MultiPolygon, { dataType: string }>[]) => {
      if (!window.Worker) return;
      updateShapeExtentsWorker.postMessage({
        previousExtents: shapeExtents.getInner(),
        coverage: shapes,
      } satisfies UpdateShapeExtentsWorkerRequest);
    },
    [shapeExtents, updateShapeExtentsWorker]
  );

  useEffect(() => {
    if (!window.Worker) return;
    updateShapeExtentsWorker.onmessage = (
      event: MessageEvent<UpdateShapeExtentsWorkerResponse>
    ) => {
      setShapeExtents(new Extents(event.data));
    };
  }, [updateShapeExtentsWorker]);

  const [coverageLayerGeometries, setCoverageLayerGeometries] = useState<
    LayerGeometries[]
  >([]);
  const [coverageLayerLabels, setCoverageLayerLabels] = useState<LayerLabels[]>(
    []
  );

  useEffect(() => {
    larkiApi
      .post('/engine/coverage/v2/query', {
        selections: [...shapeExtents.getInner().values()].map((extent) => ({
          geometry: extent.geometry,
          category_names: [extent.properties.dataType],
        })),
      })
      .then(({ data }) => {
        setCoverageLayerGeometries(data.layers);
        setCoverageLayerLabels(
          data.layers.map((l: any) => ({
            ...l,
            label_positions_original: l.label_positions,
          }))
        );
      })
      .catch(captureError);
  }, [shapeExtents]);

  // const updateLabelsWorker = useMemo(
  //   () =>
  //     new Worker(new URL('./workers/updateLabels.worker.ts', import.meta.url)),
  //   []
  // );
  const updateLabels = useCallback(
    (shapes: UpdateLabelsWorkerRequest['shapes']) => {
      // if (!window.Worker) return;
      // updateLabelsWorker.postMessage({
      //   shapes,
      //   coverageLayerLabels,
      // } satisfies UpdateLabelsWorkerRequest);
      setCoverageLayerLabels((existing) => {
        const { newLayers } = updateLabelPositions(shapes, existing);
        return newLayers;
      });
    },
    []
  );

  // useEffect(() => {
  //   if (!window.Worker) return () => {};
  //   updateLabelsWorker.onmessage = (
  //     event: MessageEvent<UpdateLabelsWorkerResponse>
  //   ) => {
  //     setCoverageLayerLabels(event.data);
  //   };
  //   return () => {
  //     updateLabelsWorker.terminate();
  //   };
  // }, [updateLabelsWorker]);

  return useMemo(
    () => ({
      shapeExtents,
      updateShapeExtents,
      coverageLayerGeometries,
      coverageLayerLabels,
      updateLabels,
    }),
    [
      shapeExtents,
      updateShapeExtents,
      coverageLayerGeometries,
      coverageLayerLabels,
      updateLabels,
    ]
  );
};
