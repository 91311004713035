import React from 'react';
import classNames from 'classnames';
import OutlinedText from '../components/text/OutlinedText';
import text from '../text';
import { Mode, ModeAction } from './RealtimeMap';
import { MapShape } from './lib/shape';

const DRAWING_CONTROLS_ID = 'drawing-controls';

// use img src rather than Icon component to make the icon look crisp/sharper
const rectangleIcon = '/public/img/rectangle-tool.svg';
const polygonIcon = '/public/img/polygon-tool.svg';
const polylineIcon = '/public/img/polyline-tool.svg';
const booleanIcon = '/public/img/boolean-tool.svg';
const undoIcon = '/public/img/undo-tool.svg';
const redoIcon = '/public/img/redo-tool.svg';

type DrawingControlsProps = {
  mode: Mode;
  activeShape: MapShape | undefined;
  handleChangeMode: (mode: ModeAction, allowRedundant?: boolean) => void;
};

const DrawingControls = ({
  mode,
  activeShape,
  handleChangeMode,
}: DrawingControlsProps) => {
  const isReadOnly = false; // TODO: replace w/ actual state
  const isBooleanToolDisabled =
    (!activeShape || activeShape?.type === 'path') &&
    mode !== 'creating_boolean';

  return (
    <div
      id={DRAWING_CONTROLS_ID}
      className={classNames('DrawingControlsMp', {
        disabled: isReadOnly,
      })}
    >
      <div className='position-relative'>
        {/* @ts-expect-error props */}
        <OutlinedText label={text('Shapes')} svgProps={{ width: '64px' }} />
        <div className='draw-menu-button-group'>
          <DrawingControlsButton
            icon={polygonIcon}
            isReadOnly={isReadOnly}
            isSelected={mode === 'creating_polygon'}
            onClick={() => handleChangeMode('start_creating_polygon')}
          />
          <DrawingControlsButton
            icon={rectangleIcon}
            isReadOnly={isReadOnly}
            isSelected={mode === 'creating_rectangle'}
            onClick={() => handleChangeMode('start_creating_rectangle')}
          />
          <DrawingControlsButton
            icon={polylineIcon}
            isReadOnly={isReadOnly}
            isSelected={mode === 'creating_path'}
            onClick={() => handleChangeMode('start_creating_path')}
          />
          <DrawingControlsButton
            icon={booleanIcon}
            isDisabled={isBooleanToolDisabled}
            isReadOnly={isReadOnly}
            isSelected={mode === 'creating_boolean'}
            onClick={() => handleChangeMode('start_creating_boolean')}
          />
        </div>
      </div>
      <div className='position-relative'>
        {/* @ts-expect-error props */}
        <OutlinedText label={text('Undo')} svgProps={{ width: '64px' }} />
        <div className='draw-menu-button-group'>
          {/* TODO: add function */}
          <DrawingControlsButton icon={undoIcon} />
          <DrawingControlsButton icon={redoIcon} />
        </div>
      </div>
    </div>
  );
};

type DrawingControlsButtonProps = {
  icon: string;
  isSelected?: boolean;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  onClick?: () => void;
};

const DrawingControlsButton = ({
  icon,
  isDisabled,
  isReadOnly,
  isSelected,
  onClick,
}: DrawingControlsButtonProps) => {
  return (
    <div
      className={classNames('draw-button', {
        disabled: isDisabled || isReadOnly,
        selected: isSelected,
      })}
      onClick={onClick}
    >
      <img src={icon} className='draw-icon' />
    </div>
  );
};

export default DrawingControls;
