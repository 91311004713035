import { useMemo, useRef } from "react";

export const useThrottle = <U extends unknown[]>(
  func: (...args: U) => void,
  opts: {
    delay: number;
    ensure?: boolean;
    deps?: React.DependencyList;
  }
) => {
  const delay = opts.delay;
  const ensure = opts.ensure ?? false;
  const lastCall = useRef(Number.NEGATIVE_INFINITY);
  const f = useRef(func);
  const handle = useRef<NodeJS.Timeout>();

  const throttled = useMemo(
    () =>
      (...args: U) => {
        const wait = lastCall.current + delay - Date.now();
        clearTimeout(handle.current);
        if (wait <= 0 || ensure) {
          handle.current = setTimeout(() => {
            f.current(...args);
            lastCall.current = Date.now();
          }, wait);
        }
      },
    [delay, ensure]
  );

  return throttled;
};
