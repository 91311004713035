import { Feature, Point } from 'geojson';
import { Corner } from './rectangle';
import { positionLib } from './position';
import { geometryLib } from './geometry';

export const SHAPE_TYPE = {
  polygon: 'polygon',
  rectangle: 'rectangle',
  path: 'path',
  boolean: 'boolean',
} as const;
export type ShapeType = (typeof SHAPE_TYPE)[keyof typeof SHAPE_TYPE];
export const TENTATIVE_SHAPE_TYPE = {
  ...SHAPE_TYPE,
  none: 'none',
} as const;
export type TentativeShapeType =
  (typeof TENTATIVE_SHAPE_TYPE)[keyof typeof TENTATIVE_SHAPE_TYPE];

export type MapPolygon = {
  type: 'polygon';
  exterior: PolygonVertexFeature[];
  holes: Map<string, BooleanVertexFeature<'polygon'>[]>;
  dataType: string;
  baseShapeId?: string;
};
export type MapRectangle = {
  type: 'rectangle';
  exterior: RectangleVertexFeature[];
  holes: Map<string, BooleanVertexFeature<'rectangle'>[]>;
  dataType: string;
  baseShapeId?: string;
};
export type MapPath = {
  type: 'path';
  vertices: PathVertexFeature[];
  dataType: string;
  baseShapeId?: string;
};
export type MapShape = MapPolygon | MapRectangle | MapPath;

export type PolygonVertexFeature = Feature<
  Point,
  {
    type: 'shape';
    shapeType: 'polygon';
    featureId: string;
    vertexId: string;
    vertexIndex: number;
  }
>;
export type RectangleVertexFeature = Feature<
  Point,
  // we use horizontalNeighborId and verticalNeighborId since the corner can change if the rectangle is flipped, but shareLngId and shareLatId stay the same
  {
    type: 'shape';
    shapeType: 'rectangle';
    featureId: string;
    vertexId: string;
    horizontalNeighborId: string;
    verticalNeighborId: string;
    corner: Corner;
  }
>;
export type PathVertexFeature = Feature<
  Point,
  { type: 'shape'; shapeType: 'path'; featureId: string; vertexId: string }
>;
// RectangleBoolean more accurate
export type BooleanVertexFeature<T extends 'polygon' | 'rectangle'> = Feature<
  Point,
  {
    type: 'shape';
    shapeType: 'boolean';
    parentShapeType: T;
    featureId: string;
    holeIndex: number;
    holeId: string;
    vertexId: string;
    parentShapeId: string;
    vertexIndex: number;
  }
>;

export type TentativeSequenceVertexFeature = Feature<
  Point,
  {
    type: 'tentative';
    sequenceIndex: number;
    clientId: string;
    vertexId: string;
  }
>;
export type TentativeFinalVertexFeature = Feature<
  Point,
  {
    type: 'tentative';
    clientId: string;
  }
>;

export type TentativePolygonVertexFeature = Feature<
  Point,
  TentativeSequenceVertexFeature['properties'] & {
    shapeType: 'polygon';
  }
>;
export type TentativePolygon = {
  type: 'polygon';
  vertices: Record<string, TentativePolygonVertexFeature>;
  final?: TentativeFinalVertexFeature;
};

export type TentativeRectangleVertexFeature = Feature<
  Point,
  {
    type: 'tentative';
    shapeType: 'rectangle';
    horizontalNeighborId: string;
    verticalNeighborId: string;
    vertexId: string;
    corner: Corner;
  }
>;
export type TentativeRectangle = {
  type: 'rectangle';
  initial: TentativeRectangleVertexFeature;
  derived: Record<string, TentativeRectangleVertexFeature>;
  final?: TentativeRectangleVertexFeature;
};

export type TentativePathVertexFeature = Feature<
  Point,
  {
    type: 'tentative';
    shapeType: 'path';
    vertexId: string;
    sequenceIndex: number;
  }
>;
export type TentativePath = {
  type: 'path';
  vertices: TentativePathVertexFeature[];
  final?: TentativePathVertexFeature;
};

export type TentativeBooleanVertexFeature = Feature<
  Point,
  TentativeSequenceVertexFeature['properties'] & { shapeType: 'boolean' }
>;
export type TentativeBoolean = {
  type: 'boolean';
  parentShapeId: string;
  vertices: Record<string, TentativeBooleanVertexFeature>;
  final?: TentativeFinalVertexFeature;
};

export type TentativeShape =
  | TentativePolygon
  | TentativeRectangle
  | TentativePath
  | TentativeBoolean;

type _VertexProperties<T> = { vertexId: string } & T;
type _Ring<T> = Map<string, Feature<Point, _VertexProperties<T>>>;
type _Holes<T> = Map<string, Feature<Point, _VertexProperties<T>>[]>;

const _toMap = <T>(ring: Feature<Point, _VertexProperties<T>>[]) =>
  new Map(ring.map((v) => [v.properties.vertexId, v]));

const _areRingsEqual = <T>(
  a: _Ring<T>,
  b: _Ring<T>,
  compareProps: (a: T, b: T) => boolean = () => true
) => {
  if (a.size !== b.size) return false;
  const paths = new Set([...a.keys(), ...b.keys()]);
  for (const vId of paths) {
    const aV = a.get(vId);
    const bV = b.get(vId);
    if (!aV) return false;
    if (!bV) return false;
    if (!positionLib.equal(aV.geometry.coordinates, bV.geometry.coordinates))
      return false;
    if (!compareProps(aV.properties, bV.properties)) return false;
  }
  return true;
};

const _areHolesEqual = <T>(
  a: _Holes<T>,
  b: _Holes<T>,
  compareProps: (a: T, b: T) => boolean = () => true
) => {
  if (a.size !== b.size) return false;
  const holeIds = new Set([...a.keys(), ...b.keys()]);
  for (const hId of holeIds) {
    const aH = a.get(hId);
    const bH = b.get(hId);
    if (!aH) return false;
    if (!bH) return false;
    if (aH.length !== bH.length) return false;
    if (!_areRingsEqual(_toMap(aH), _toMap(bH), compareProps)) return false;
  }
  return true;
};

export const areMapShapesEqual = (a: MapShape, b: MapShape) => {
  if (a.dataType !== b.dataType) return false;
  switch (a.type) {
    case 'polygon': {
      if (b.type !== a.type) return false;
      return (
        _areRingsEqual(_toMap(a.exterior), _toMap(b.exterior)) &&
        _areHolesEqual(a.holes, b.holes)
      );
    }
    case 'rectangle': {
      if (b.type !== a.type) return false;
      return (
        _areRingsEqual(_toMap(a.exterior), _toMap(b.exterior)) &&
        _areHolesEqual(a.holes, b.holes)
      );
    }
    case 'path': {
      if (b.type !== a.type) return false;
      return _areRingsEqual(_toMap(a.vertices), _toMap(b.vertices));
    }
  }
};

export const areTentativeShapesEqual = (
  a: TentativeShape,
  b: TentativeShape
) => {
  switch (a.type) {
    case 'polygon': {
      if (b.type !== a.type) return false;
      const exteriorEqual = _areRingsEqual(
        _toMap(Object.values(a.vertices)),
        _toMap(Object.values(b.vertices))
      );
      const finalsEqual =
        (!a.final && !b.final) ||
        (!!(a.final && b.final) &&
          positionLib.equal(
            a.final.geometry.coordinates,
            b.final.geometry.coordinates
          ));
      return exteriorEqual && finalsEqual;
    }
    case 'rectangle': {
      if (b.type !== a.type) return false;
      const aDerived = _toMap(Object.values(a.derived));
      const bDerived = _toMap(Object.values(b.derived));
      const initialEqual = positionLib.equal(
        a.initial.geometry.coordinates,
        b.initial.geometry.coordinates
      );
      const derivedEqual = _areRingsEqual(aDerived, bDerived);
      const finalsEqual =
        (!a.final && !b.final) ||
        (!!(a.final && b.final) &&
          positionLib.equal(
            a.final.geometry.coordinates,
            b.final.geometry.coordinates
          ));
      return initialEqual && derivedEqual && finalsEqual;
    }
    case 'path': {
      if (b.type !== a.type) return false;
      const finalsEqual =
        (!a.final && !b.final) ||
        (!!(a.final && b.final) &&
          positionLib.equal(
            a.final.geometry.coordinates,
            b.final.geometry.coordinates
          ));
      return (
        _areRingsEqual(_toMap(a.vertices), _toMap(b.vertices)) && finalsEqual
      );
    }
    case 'boolean': {
      if (b.type !== a.type) return false;
      const aVertices = _toMap(Object.values(a.vertices));
      const bVertices = _toMap(Object.values(b.vertices));
      const parentsEqual = a.parentShapeId === b.parentShapeId;
      const verticesEqual = _areRingsEqual(aVertices, bVertices);
      const finalsEqual =
        (!a.final && !b.final) ||
        (!!(a.final && b.final) &&
          positionLib.equal(
            a.final.geometry.coordinates,
            b.final.geometry.coordinates
          ));
      return parentsEqual && verticesEqual && finalsEqual;
    }
  }
};
