import { Color, CompositeLayer } from '@deck.gl/core';
import { IconLayer, TextLayer } from '@deck.gl/layers';

import { Position } from 'geojson';
import { svgToDataUrl } from './svg';
import { WorldState } from './worldState';
import { ClientColor, UserPool } from './RealtimeMap';

// type CursorLayerState = {
//   interpolator: WorldStateInterpolator;
// };
// type CursorLayerData = {
//   // id: string;
//   position: Position;
// };

// TODO: Should save as file? I'm not sure though if it should be inside mp
const CURSOR_SVG = `<svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45" fill="none">
                      <path d="M19.6204 41.4616L21.3449 44.0414L26.2947 24.9495L43.7797 19.9998L43.7799 17.4998L3.87451 3.8996L19.6204 41.4616Z" fill="#2A2A2A"/>
                      <path d="M24.476 21.8674L24.1913 21.9412L24.1175 22.2259L19.54 39.8821L3.26033 1.01024L42.1322 17.2899L24.476 21.8674Z" fill="#05E1A4" stroke="#027959"/>
                    </svg>`;

type Projector = (xy: Position) => Position;

// class WorldStateInterpolator {
//   private interpolators: Record<string, PointInterpolator> = {};
//   private cb: (state: WorldState) => void;
//   private project: Projector;
//   private unproject: Projector;

//   constructor(
//     cb: (state: WorldState) => void,
//     project: Projector,
//     unproject: Projector
//   ) {
//     this.cb = cb;
//     this.project = project;
//     this.unproject = unproject;
//   }

//   addState(state: WorldState) {
//     if (!this.project) {
//       console.warn("no projector");
//       return;
//     }
//     const cursors = state.getCursors();
//     for (const [clientId, position] of Object.entries(cursors)) {
//       (this.interpolators[clientId] ??= new PointInterpolator((point) => {
//         if (!this.unproject) {
//           console.warn("no unprojector");
//           return;
//         }
//         this.cb(state.interpolate(clientId, this.unproject(point)));
//       })).addPoint(this.project(position));
//     }
//   }
// }

type CursorLayerProps = {
  clientId: string | null;
  data: Record<string, Position>;
  cb: (interpolated: WorldState) => void;
  zoom: number;
  color: ClientColor;
  userPool: UserPool;
};

export default class CursorLayer extends CompositeLayer<CursorLayerProps> {
  renderLayers() {
    const iconWidth = 24;
    const iconHeight = 24;
    const { data: _data, clientId, userPool } = this.props;
    const data = Object.entries(_data)
      .filter(([cId]) => cId !== clientId)
      .map(([id, position]) => ({
        id,
        position,
      }));

    return [
      new IconLayer<{ position: Position; id: string }>({
        id: 'cursor-layer-arrow',
        data,
        getIcon: (d) => {
          const {
            setting: {
              color: { hex },
            },
          } = userPool[d.id];
          return {
            url: svgToDataUrl(CURSOR_SVG, hex),
            width: iconWidth,
            height: iconHeight,
          };
        },
        getPixelOffset: [8, 10],
        getSize: iconWidth,
        getPosition: (c) => c.position as [number, number],
      }),
      new TextLayer<{ position: Position; id: string }>({
        id: 'cursor-layer-text',
        data,
        getText: (d) => d.id,
        getAlignmentBaseline: 'center',
        getColor: (d) => {
          const {
            setting: {
              color: { textColor },
            },
          } = userPool[d.id];
          return textColor as Color;
        },
        getSize: 12,
        getTextAnchor: 'start',
        pickable: true,
        getPosition: (c) => c.position as [number, number],
        background: true,
        backgroundPadding: [9, 3],
        getBackgroundColor: (d) => {
          const {
            setting: {
              color: { rgb },
            },
          } = userPool[d.id];
          return rgb as Color;
        },
        fontWeight: 700,
        // Apply pixel offset, rough multiplier on x & y to position bottom right of the arrow
        getPixelOffset: [iconWidth / 0.6, iconHeight / 1.3],
      }),
    ];
  }
}
