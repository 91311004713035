import {
  Position,
  GeoJsonProperties,
  Feature,
  Polygon,
  MultiPolygon,
  Point,
} from 'geojson';
import * as turf from '@turf/turf';
// @ts-expect-error no types
import * as polyclip from 'polyclip-ts';

const squaredDistance = (a: Position, b: Position) => {
  const dx = a[0] - b[0];
  const dy = a[1] - b[1];
  return dx * dx + dy * dy;
};

const midpoint = (a: Position, b: Position) => {
  return [(a[0] + b[0]) / 2, (a[1] + b[1]) / 2];
};

/**
 * turf.difference but keeps the properties of the 1st argument
 */
const difference = <P extends GeoJsonProperties>(
  a: Feature<Polygon | MultiPolygon, P>,
  b: Feature<Polygon | MultiPolygon>
): Feature<Polygon | MultiPolygon, P> | null => {
  const diff = turf.difference(turf.featureCollection([a, b]));
  if (!diff) return null;
  const diff2: Feature<Polygon | MultiPolygon, P> = {
    ...diff,
    properties: a.properties,
  };
  return diff2;
};

const getCoords = (geometry: Feature<Point>[]) => {
  return geometry.map((p) => p.geometry.coordinates);
};

const union = <P extends GeoJsonProperties>(
  features: Feature<Polygon | MultiPolygon, P>[],
  properties: P
) => {
  const union: MultiPolygon['coordinates'] | null = polyclip.union(
    ...features.map((f) => f.geometry.coordinates)
  );
  // const union = turf.union(turf.featureCollection(features)) as Feature<
  //   Polygon | MultiPolygon,
  //   P
  // >;
  // if (union) {
  //   union.properties = properties;
  // }
  return union ? turf.multiPolygon(union, properties) : null;
};

export const geometryLib = {
  squaredDistance,
  midpoint,
  difference,
  getCoords,
  union,
};
